import { Box, Card, Icon, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import truck from 'assets/images/truck.png';
import trailer from 'assets/images/trailer.png';

// import { StyledBox } from './styled';

interface IProps {
  data: { [key: string]: any }[];
  isGridView: boolean;
  handleChange: () => void;
  showAssetForm: () => void;
}

export const AssetsCard: React.FC<IProps> = (props) => {
  const { data, isGridView, handleChange, showAssetForm } = props;

  return (
    <React.Fragment>
      <Stack direction="row" justifyContent="flex-end" gap={1} sx={{ margin: '10px 0' }}>
        <Box sx={{ background: '#DBE1E6', borderRadius: '12px' }}>
          <IconButton onClick={handleChange}>
            <Icon
              fontSize="small"
              color="inherit"
              sx={!isGridView ? { background: 'white', borderRadius: '6px' } : {}}
            >
              list
            </Icon>
          </IconButton>
          <IconButton onClick={handleChange}>
            <Icon
              fontSize="small"
              color="inherit"
              sx={isGridView ? { background: 'white', borderRadius: '6px' } : {}}
            >
              grid_view
            </Icon>
          </IconButton>
        </Box>
        <IconButton disabled>
          <Icon fontSize="small" color="inherit">
            manage_search
          </Icon>
        </IconButton>
        <IconButton disabled>
          <Icon fontSize="small" color="inherit">
            settings
          </Icon>
        </IconButton>
        <IconButton onClick={showAssetForm}>
          <Icon fontSize="small" color="inherit">
            add
          </Icon>
        </IconButton>
      </Stack>
      <Stack direction="row" justifyContent="center" flexWrap="wrap" gap={3}>
        {data &&
          data?.map((assets) => {
            return (
              <Card key={assets.id} sx={{ padding: '15px', color: '#344767' }}>
                <Stack direction="row" justifyContent="space-between" sx={{ marginBottom: '10px' }}>
                  <Stack direction="column" alignItems="space-between" gap={1}>
                    <Typography variant="h6">{assets?.unitId}</Typography>
                    <Typography variant="subtitle2">{assets?.year + ' ' + assets?.make}</Typography>
                    <Typography variant="h6" sx={{ color: '#4CAF50' }}>
                      {assets.status}
                    </Typography>
                  </Stack>
                  <img src={assets?.type === 'Truck' ? truck : trailer} alt="truck" />
                </Stack>
                <Stack direction="column" gap={1}>
                  <Typography variant="subtitle2">{assets?.vin && 'VIN: ' + assets.vin}</Typography>
                  <Typography variant="subtitle2">
                    {assets?.plate && 'Plate: ' + assets?.state + assets.plate}
                  </Typography>
                  {/* <Typography variant="subtitle2">12567 Miles</Typography> */}
                </Stack>
                {/* <Stack direction="row" gap={2} sx={{ margin: '10px 0' }}>
                  <StyledBox>ELD</StyledBox>
                  <StyledBox>GPS</StyledBox>
                  <StyledBox>CAMERA</StyledBox>
                </Stack> */}
                <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '15px' }}>
                  <Stack direction="row" gap={1}>
                    <Icon sx={{ color: '#ff8a00' }} fontSize="small" color="inherit">
                      content_paste_off
                    </Icon>
                    <Icon sx={{ color: '#ff312f' }} fontSize="small" color="inherit">
                      content_paste_off
                    </Icon>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <IconButton disabled>
                      <Icon fontSize="small" color="inherit">
                        manage_search
                      </Icon>
                    </IconButton>
                    <IconButton>
                      <Icon
                        fontSize="small"
                        color="inherit"
                        onClick={() => assets?.actions?.edit()}
                      >
                        edit
                      </Icon>
                    </IconButton>
                    <IconButton onClick={() => assets?.actions?.delete()}>
                      <Icon fontSize="small" color="inherit">
                        delete
                      </Icon>
                    </IconButton>
                  </Stack>
                </Stack>
              </Card>
            );
          })}
      </Stack>
    </React.Fragment>
  );
};

export default AssetsCard;

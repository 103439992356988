import { filterFilesByRoles } from 'constants/Util';

import { Document, DocumentRoleType, DocumentStatus, DocumentType } from 'constants/Document';

import React from 'react';
import MDBox from 'components/common/MDBox';
import { Icon, IconButton, Tooltip, styled } from '@mui/material';
import MDTypography from 'components/common/MDTypography';

import {
  EmployeeDocumentConfig,
  EmployeeDocumentType,
} from 'screens/roster/employees/schemas/employeeConfig';

const StyledIconButton = styled(IconButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

interface Props {
  row: any;
  actions: any;
}

const getInfoLabel = (type: DocumentType) => {
  switch (type) {
    case EmployeeDocumentType.CDL:
      return 'CDL';
    case EmployeeDocumentType.MVR:
      return 'MVR';
    case EmployeeDocumentType.Contract:
      return 'CONT';
    case EmployeeDocumentType.PSP:
      return 'PSP';
    case EmployeeDocumentType.DrugTest:
      return 'DRUG';
    case EmployeeDocumentType.MEDCard:
      return 'MED';
    default:
      return 'MISC';
  }
};

const getStatusIcon = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.MISSING:
      return 'content_paste_search';
    case DocumentStatus.PENDING:
    case DocumentStatus.EXPIRING:
      return 'content_paste_go';
    case DocumentStatus.REJECTED:
    case DocumentStatus.EXPIRED:
    case DocumentStatus.CANCELLED:
      return 'content_paste_off';
    default:
      return 'content_paste';
  }
};

const getStatusColor = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.EXPIRING:
      return 'warning';
    case DocumentStatus.REJECTED:
    case DocumentStatus.EXPIRED:
    case DocumentStatus.CANCELLED:
    case DocumentStatus.MISSING:
      return 'error';
    case DocumentStatus.SUBMITTED:
    case DocumentStatus.PENDING:
      return 'info';
    case DocumentStatus.APPROVED:
    default:
      return 'success';
  }
};

const getStatusNote = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.MISSING:
      return 'Missing';
    case DocumentStatus.PENDING:
      return 'Pending';
    case DocumentStatus.EXPIRING:
      return 'Expiring';
    case DocumentStatus.REJECTED:
      return 'Rejected';
    case DocumentStatus.EXPIRED:
      return 'Expired';
    case DocumentStatus.CANCELLED:
      return 'Cancelled';
    default:
      return 'Valid';
  }
};

const getLatesVersionKey = (availableVersionKeys: string[]) => availableVersionKeys[0];

const getExpireTimesttamp = (file: Document) => {
  let expireTimestamp;
  const expirationConfig = EmployeeDocumentConfig[file.name]?.expiration;

  if (file?.expiration?.expireTimestamp) {
    expireTimestamp = file.expiration.expireTimestamp;
  }

  if (expirationConfig?.expireInDays && file?.issueTimestamp) {
    expireTimestamp = file.issueTimestamp + expirationConfig.expireInDays * 24 * 60 * 60 * 1000;
  }

  return expireTimestamp;
};

const isFileExpired = (expireTimestamp: number | undefined, now: number) =>
  expireTimestamp && expireTimestamp < now;

const isFileExpiring = (expireTimestamp: number | undefined, now: number, notifyInDays: number) =>
  expireTimestamp && notifyInDays && expireTimestamp < now + notifyInDays * 24 * 60 * 60 * 1000;

const generateInfoRow = (row: any, onEdit: any) => {
  const requiredFileKeys: string[] = filterFilesByRoles(
    EmployeeDocumentConfig,
    row.roles,
    DocumentRoleType.REQUIRED
  );

  const infoIcons = requiredFileKeys.map((requiredFileKey) => {
    let status: DocumentStatus;
    let version: string = '0';

    const availableFileKeys: string[] = Object.keys(row?.files || {});

    if (availableFileKeys.includes(requiredFileKey)) {
      const requiredFiles: { [key: string]: Document } = row.files[requiredFileKey];
      const requiredVersionKeys: string[] = Object.keys(requiredFiles).reverse();

      const key = getLatesVersionKey(requiredVersionKeys);

      if (requiredFiles[key].file) {
        const expireTimestamp = getExpireTimesttamp(requiredFiles[key]);
        const notifyInDays = EmployeeDocumentConfig[requiredFileKey]?.expiration?.notifyInDays || 0;
        const now = Date.now();

        if (isFileExpired(expireTimestamp, now)) {
          status = DocumentStatus.EXPIRED;
          version = key;
        } else if (isFileExpiring(expireTimestamp, now, notifyInDays)) {
          status = DocumentStatus.EXPIRING;
          version = key;
        } else {
          return null;
        }
      } else {
        status = DocumentStatus.MISSING;
      }
    } else {
      status = DocumentStatus.MISSING;
    }

    return (
      <Tooltip
        title={`${EmployeeDocumentConfig[requiredFileKey].name} ${getStatusNote(status)}`}
        key={requiredFileKey}
      >
        <StyledIconButton
          color={getStatusColor(status)}
          key={requiredFileKey as string}
          onClick={() => {
            onEdit(row, { main: 'Files', options: requiredFileKey, version });
          }}
        >
          <Icon fontSize="small">{getStatusIcon(status)}</Icon>
          <MDTypography variant="icon" color="inherit">
            {getInfoLabel(EmployeeDocumentConfig[requiredFileKey].type)}
          </MDTypography>
        </StyledIconButton>
      </Tooltip>
    );
  });

  return infoIcons;
};

function DataTableInfoCell({ row, actions }: Props): JSX.Element {
  return (
    <MDBox display="flex" justifyContent="space-between">
      {generateInfoRow(row, actions.edit)}
    </MDBox>
  );
}

export default DataTableInfoCell;

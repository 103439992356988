import { Load, LoadRoute, LoadRouteStop, LoadSize, LoadStatus } from 'constants/Load';
import { formatFiles } from 'constants/Util';

import { LoadDocumentType } from '../schemas/loadsConfig';

export const LoadVersion: number = 2;

const formatRoute = (route: LoadRoute): LoadRoute => ({
  stops: route?.stops.map((stop: LoadRouteStop) => ({
    type: stop?.type || '',
    location: stop?.location,
    timestamp: stop?.timestamp || null,
    timeFrom: stop?.timeFrom || null,
    timeTill: stop?.timeTill || null,
  })),
});

const formatLoadV1 = (data: any): Load => ({
  id: data?.uid || '',
  info: {
    id: data?.loadNumber || '',
    size: LoadSize.Full,
    milesLoaded: '',
    type: null,
  },
  accounting: {
    rate: data?.loadRate || '',
  },
  status: LoadStatus.BOOKED,
  bookedBy: '',
  assignedTo: '',
  broker: '',
  route: {
    stops: [],
  },
  version: 2,
  files: formatFiles(LoadDocumentType, data?.files || {}),
  events: data?.events,
});

const formatLoadV2 = (data: any): Load => ({
  id: data.id || '',
  info: {
    id: data?.info?.id || '',
    size: data?.info?.size || LoadSize.Full,
    milesLoaded: data?.info?.milesLoaded || '',
    type: data?.info?.type,
  },
  accounting: {
    rate: data?.accounting?.rate || '',
  },
  status: data?.status || '',
  bookedBy: (data?.bookedBy && { id: data?.bookedBy.id }) || null,
  assignedTo: (data?.assignedTo && { id: data?.assignedTo.id }) || null,
  broker: (data?.broker && { id: data?.broker.id }) || null,
  route: data?.route?.stops ? formatRoute(data.route) : null,
  version: LoadVersion,
  files: formatFiles(LoadDocumentType, data?.files || {}),
  events: data?.events,
});

export const formatLoadData = (data: any): Load => {
  const version = data?.version || 1;

  switch (version) {
    case 1: {
      return formatLoadV1(data);
    }

    case 2: {
      return formatLoadV2(data);
    }

    default: {
      return formatLoadV1(data);
    }
  }
};

export const formatLoadsData = (dataObject: any) =>
  dataObject ? Object.values(dataObject).map((data) => formatLoadData(data)) : [];

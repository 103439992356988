export interface UpdateTimelineProps {
  stops: UpdateStop[];
}

export interface UpdateStop {
  status: any; //stop status
  timestamp: number; // status change timestamp
  type: any; // stop type PICK, DROP, STOP, FUEL, SCALE, ACCIDENT, INSPECTION, BREAKDOWN, MAINTENANCE, RESET, OFF, EXHANGE
  distance: number; // distance to THIS stop, 0 for the first stop
  appointment: string; //location APPOINTMENT or Open Hours
  address: string; //location's CITY, STATE and ZIP unless id is present
  color: UpdateStopColor; //stop color: gray for stops when empty otherwise color is based on load
  isActive: boolean; //if true 100% opasity else if false 20% opacity
  id?: string; // location's unique ID
  icon?: string; // location's icon
  isLastStop?: boolean;
  isFirstStop?: boolean;
}

export enum UpdateStopColor {
  GREY = '#7B809A',
  GREEN = '#4CAF50',
  BLUE = '#1A73E7',
  ORANGE = '#FB8C00',
}

export const UpdateStopColorList = [
  UpdateStopColor.GREEN,
  UpdateStopColor.BLUE,
  UpdateStopColor.ORANGE,
]

import { FieldType } from 'constants/form/Form';

import FormField from 'screens/authentication/components/RegistrationCard/form/steps/FormField';

import { resolvePath } from '../Utils';

export const formatTimestamp = (timestamp: number | null | undefined, type: FieldType = FieldType.TEXT) => {
  if (timestamp === null || timestamp === undefined) {
    return '';
  }

  switch (type) {
    case FieldType.DATE:
      return timestamp ? new Date(timestamp).toISOString()?.split('T')[0] : '';

    case FieldType.TIME: {
      const seconds = timestamp / 1000;
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    }

    case FieldType.DATETIME:
      return new Date(timestamp).toISOString();

    default: {
      const date = new Date(timestamp);

      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
    }
  }
};

function InputField(formData: any) {
  const {
    config,
    values,
    validation: { errors, touched },
    actions,
  } = formData;

  const {
    type,
    label,
    name,
    placeholder,
    composition: { disabled } = { disabled: false },
    validation,
    control: { formatBy, source } = { formatBy: undefined, source: undefined },
  } = config;

  const timeToTimestamp = (time: string) => {
    const timeArray: string[] = time.split(':');

    return (parseInt(timeArray[0], 10) * 3600 + parseInt(timeArray[1], 10) * 60) * 1000;
  };

  const onDateChange = (event: any) => {
    switch (type) {
      case FieldType.DATE:
        actions.setFieldValue(event.target.name, new Date(event.target.value).getTime());
        break;
      case FieldType.TIME:
        actions.setFieldValue(event.target.name, timeToTimestamp(event.target.value));
        break;
      case FieldType.DATETIME:
        actions.setFieldValue(event.target.name, new Date(event.target.value).getTime());
        break;
      default:
        actions.setFieldValue(event.target.name, new Date(event.target.value).getTime());
    }
  };

  const isDateType = (type: string) =>
    ['date', 'datetime', 'time', 'datetime-local'].includes(type);

  const getValue = (values: string) =>
    formatTimestamp(
      formatBy ? formatBy(resolvePath(values, source, '')) : resolvePath(values, name, ''),
      type
    );

  const shouldShrinkLabel = () =>
    resolvePath(values, name, undefined)?.length || isDateType(type) || undefined;

  return (
    <FormField
      type={type}
      label={label}
      name={name}
      value={getValue(values)}
      placeholder={placeholder}
      onChange={(event: any) => onDateChange(event)}
      disabled={disabled}
      error={resolvePath(errors, name, null) && resolvePath(touched, name, null)}
      success={
        validation !== undefined &&
        resolvePath(values, name, '')?.length > 0 &&
        !resolvePath(errors, name, null)
      }
      InputLabelProps={{
        shrink: shouldShrinkLabel(),
      }}
    />
  );
}

export default InputField;

import { AssetStatus } from 'constants/Asset';

import formatAssetData, { AssetVersion } from 'screens/roster/assets/migration';

const formatAssetValues = (values: any) => ({
  unitId: values?.unitId,
  vin: values?.vin,
  year: values?.year,
  make: values?.make,
  type: values?.type,
  plate: values?.plate,
  state: values?.state,
  status: values?.status || AssetStatus.PENDING,
  unit: values?.unit,
  files: values?.files || {},
  id: values.id || '',
  version: values.version || AssetVersion,
  initialTabs: values?.initialTabs || {},
});

export const formatAssetSelect = (asset: any) => ({
  ...asset,
  name: `#${asset.unitId} ${asset.year} ${asset.make}`,
});

export const formatAssetsValuesList = (assets: any) =>
  assets
    ? Object.values(assets)
        .filter((asset) => asset)
        .map((asset: any) =>
          formatAssetValues({
            ...formatAssetData(asset),
          })
        )
    : [];

export default formatAssetValues;

import { UnitStatus } from 'constants/Unit';

import { formatLoadSelect } from 'screens/freight/loads/schemas/formatLoadValues';
import { formatAssetSelect } from 'screens/roster/assets/formatAsset';
import { formatDriverSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';
import { useDeepCompareMemo } from 'use-deep-compare';

import { formatUnitData } from '../data/migration';

const formatUnitValues = (values: any) => ({
  id: values?.id || '',
  status: values?.status || UnitStatus.READY,
  drivers: values?.drivers && values.drivers.map((driver: any) => formatDriverSelect(driver)),
  truck: values?.truck ? formatAssetSelect(values.truck) : undefined,
  trailer: values?.trailer ? formatAssetSelect(values.trailer) : undefined,
  loads: values?.loads?.map((load: any) => formatLoadSelect(load)),
  version: values?.version,
  events: values?.events,
});

export const formatUnitSelect = (unit: any) => ({
  ...unit,
  name: `${unit?.truck ? `#${unit.truck.unitId} ${unit.truck.year} ${unit.truck.make} | ` : ''}${
    unit?.drivers
      ?.map((driver: any) => (driver.name ? driver.name : `${driver.firstName} ${driver.lastName}`))
      .join(' & ') || ''
  }`,
});

export const formatUnitsValuesList = (units: any, employees: any, assets: any, loads: any) =>
// eslint-disable-next-line react-hooks/rules-of-hooks
  useDeepCompareMemo(
    () =>
      units
        ? Object.keys(units)
            .filter((key: any) => units?.[key])
            .map((key: string) => {
              const unit = units[key];
              const unitDrivers =
                employees &&
                unit?.drivers &&
                unit?.drivers?.map((driver: any) => employees[driver.id]);

              const unitTrailer = assets && unit?.trailer?.id && assets[unit?.trailer?.id];
              const unitTruck = assets && unit?.truck?.id && assets[unit?.truck?.id];
              const unitLoads = loads && unit?.loads?.map((load: any) => loads[load?.id]);

              return formatUnitValues({
                ...formatUnitData(unit),
                id: key,
                drivers: unitDrivers,
                trailer: unitTrailer,
                truck: unitTruck,
                loads: unitLoads,
              });
            })
        : [],
    [units, employees, loads, assets]
  );

export default formatUnitValues;

/* eslint-disable no-unused-vars */
enum ROUTES {
  AUTHENTICATION = '/authentication',
  SIGN_UP = '/signup',
  SIGN_IN = '/signin',
  HOME = '/home',
  ACCOUNT = '/account',
  ACCOUNTING = '/accounting',
  PASSWORD_FORGET = '/reset',
  ADMIN = '/admin',
  ADMIN_DETAILS = '/admin/:id',
  DASHBOARD = '/dashboard',
  REGISTER = '/register',
  LOGIN = '/login',

  DRIVERS = '/drivers',
  ROSTER = '/roster',
  CONNECTIONS = '/connections',
  ACCOUNTS = '/accounts',
  INTEGRATIONS = '/integrations',

  DISPATCH = '/dispatch',
  BOARD = '/board',

  FREIGHT = '/freight',
  LOADS = '/loads',

  EMPLOYEES = '/employees',
  CONTRACTORS = '/contractors',
  ASSETS = '/assets',
  GROUPS = '/groups',

  CALENDAR = '/calendar',

  STATEMENTS = '/statements',
  BROKERS = '/brokers',
  FACTORING = '/factoring',
  EXPENSES = '/expenses',

  COMPANY = '/company',
  PROFILE = '/profile',
}

export default ROUTES;

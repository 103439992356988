// import { useState } from "react";
// import { useSelector } from "react-redux";

import { Load, LoadEventAction, LoadRouteType, LoadSize, LoadStatus, LoadType } from 'constants/Load';

import { UpdateStatusList, UpdateType } from 'constants/Dispatch';
import { UnitEventAction, UnitStatus } from 'constants/Unit';

import withAuthorization from 'session/withAuthorization';

import Card from '@mui/material/Card';

import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import { Grid, Icon, IconButton } from '@mui/material';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import DataTableRouteCell, { DataTableLoadCell } from 'components/DataTable/DataTableRouteCell';
import MDTypography from 'components/common/MDTypography';
import DataTableUnitCell from 'components/DataTable/DataTableUnitCell';

import LastLocationCell from 'components/DataTable/LastLocationCell';
import UnitETACell from 'components/DataTable/UnitETACell';
import useFirebaseUnitAPI from 'services/data/Firebase/firebaseUnitAPI';
import useFirebaseLoadAPI from 'services/data/Firebase/firebaseLoadAPI';
import { useDeepCompareMemo } from 'use-deep-compare';
import useBaseForm from 'hooks/useBaseForm';

import './styles/board.css';
import { getRoutes } from 'utils/getRoutes';

import { DispatchForm } from '../DispatchForm';

const columns = [
  {
    Header: 'Unit',
    accessor: ({ truck }: any) => (truck ? `${truck?.unitId} ${truck?.year} ${truck?.make}` : ''),
    width: '7%',
    Cell: ({ data, row }: any) => <DataTableUnitCell unit={data[row.id].truck} />,
  },
  {
    Header: 'Trailer',
    accessor: ({ trailer }: any) =>
      trailer ? `${trailer?.unitId} ${trailer?.year} ${trailer?.make}` : '',
    width: '7%',
    Cell: ({ data, row }: any) => <DataTableUnitCell unit={data[row.id].trailer} />,
  },
  {
    Header: 'Contact',
    accessor: ({ drivers }: any) =>
      drivers?.map((driver: any) => `${driver.name} ${driver.contact?.phone}`).join(''),
    width: '10%',
    Cell: ({ data, row }: any) => (
      <Grid container item direction="column">
        {data[row.id].drivers?.map((driver: any) => (
          <Grid key={driver} container item direction="column">
            <MDTypography variant="overline" color="text" fontWeight="bold" lineHeight="inherit">
              {driver.name}
            </MDTypography>
            <MDTypography variant="overline" color="text" lineHeight="inherit">
              {driver.contact?.phone}
            </MDTypography>
          </Grid>
        ))}
      </Grid>
    ),
  },
  {
    Header: 'Last Location',
    accessor: 'id',
    width: '15%',
    Cell: ({ value }: any) => <LastLocationCell unitId={value} />,
  },
  {
    Header: 'Next Stop',
    accessor: 'load',
    width: '15%',
    Cell: ({ value }: any) => (
      <DataTableLoadCell stop={value?.route?.stops?.length ? value?.route?.stops[0] : null} />
    ),
  },
  {
    Header: 'Eta',
    accessor: 'eta',
    Cell: ({ row }: any) => (
      <UnitETACell unitId={row?.values.id} nextStop={row.values?.load?.route?.stops[0]} />
    ),
  },
  {
    Header: 'actions',
    accessor: 'actions',
    Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
    alignCell: 'right',
    isSortable: false,
    width: '3%',
  },
];

const columnsTodo = [
  {
    Header: 'route',
    accessor: ({ route }: any) => getRoutes(route?.stops),
    Cell: ({ data, row }: any) => (
      <DataTableRouteCell values={data[row.id]} actions={data[row.id]?.actions} />
    ),
  },
  {
    Header: 'actions',
    accessor: 'actions',
    Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
    alignCell: 'right',
    isSortable: false,
    width: '5%',
  },
];

function Units() {
  // const lastUnitInfo = useSelector((state: any) => state?.units?.lastUnitInfo);
  const { showUnitForm, showUnitDeleteDialog, showLoadForm } = useBaseForm();
  const { units } = useFirebaseUnitAPI();
  const { loads } = useFirebaseLoadAPI();

  // const [dispatchFormValues, setDispatchFormValues] = useState<any>({});

  // const [isDispatchDialogOpen, setDispatchDialogOpen] = useState<boolean>(false);

  // const onDispatch = (load: any) => {
  //   setDispatchFormValues(load);
  //   setDispatchDialogOpen(true);
  // };

  const mapUnits = (units: any) =>
    (units &&
      units.map((unit: any) => ({
        ...unit,
        actions: {
          // dispatch: () => onDispatch(unit.load),
          edit: (values: any = unit, tabs: any = {}) =>
            showUnitForm({ ...values, initialTabs: tabs }),
          delete: () => showUnitDeleteDialog(unit),
        },
      }))) ||
    [];

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={columns}
        data={mapUnits(units)}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled={process.env.FEATURE_EMPLOYEE_EVENTS !== 'true'}>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <IconButton onClick={() => showUnitForm()}>
              <Icon fontSize="small" color="inherit">
                add
              </Icon>
            </IconButton>
          </>
        }
      />
    ),
    [units]
  );

  const filterAssignableLoad = (load: Load) =>
    load &&
    (!load.assignedTo || load.assignedTo === '') &&
    load.status &&
    UpdateStatusList[load.status]?.role.includes(UpdateType.UNIT);

  const mapAssignableLoads = (loads: any) =>
    (loads &&
      Object.values(loads)
        .filter((load: any) => filterAssignableLoad(load))
        .map((load: any) => {
          if (!load) {
            return null;
          }

          return {
            ...load,
            actions: {
              // dispatch: () => onDispatch(load),
              // cancel: () => setDispatchDialogOpen(false),
              confirm: () => {},
              edit: (values: any = load, tabs: any = {}) =>
                showLoadForm({ ...values, initialTabs: tabs }),
            },
          };
        })
        .filter((load) => load)) ||
    [];

  const generateAssignableTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={columnsTodo}
        data={mapAssignableLoads(loads)}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled={process.env.FEATURE_EMPLOYEE_EVENTS !== 'true'}>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <IconButton onClick={() => showLoadForm()}>
              <Icon fontSize="small" color="inherit">
                add
              </Icon>
            </IconButton>
          </>
        }
      />
    ),
    [loads]
  );

  const shouldShowLoadTable = true;

  return (
    <DashboardLayout>
      <DispatchForm
        loads={[
          {
            id: '7u3xBYhrQBXQzoU7MV9d',
            status: LoadStatus.LOADING,
            info: {
              id: '1234-R',
              size: LoadSize.Full,
              milesLoaded: '1200',
              type: LoadType.PO,
            },
            version: 2,
            files: {},
            accounting: {
              rate: '1200',
            },
            route: {
              stops: [
                {
                  type: LoadRouteType.Pick,
                  event: {
                    status: LoadStatus.LOADING,
                    timestamp: 5,
                    action: LoadEventAction.EDIT,
                  },
                  location: {
                    address: '1762 1st Ave.',
                    city: 'New York',
                    state: 'NY',
                    zip: '10128',
                    coordinates: {
                      lat: '40.7803092',
                      lng: '-73.9466757',
                    },
                  },
                  timestamp: 5,
                  timeFrom: 62400,
                  timeTill: 62400,
                },
                {
                  type: LoadRouteType.Drop,
                  event: {
                    status: LoadStatus.SCHEDULED,
                    timestamp: 6,
                    action: LoadEventAction.EDIT,
                  },
                  location: {
                    name: '239 Butler St',
                    address: '239 Butler St',
                    city: 'Trenton',
                    state: 'NJ',
                    zip: '08611',
                    coordinates: {
                      lat: '40.2138078',
                      lng: '-74.74797819999999',
                    },
                  },
                  timestamp: 6,
                  timeFrom: 22400,
                  timeTill: 82400,
                },
              ],
            },
          },
          {
            id: 'o3XhsZ9IUsm4YlsMgJKQ',
            status: LoadStatus.DISPATCHED,
            route: {
              stops: [
                {
                  type: LoadRouteType.Pick,
                  event: {
                    status: LoadStatus.DISPATCHED,
                    timestamp: 1,
                    action: LoadEventAction.EDIT,
                  },
                  location: {
                    address: '',
                    city: 'Wellington',
                    state: 'FL',
                    zip: '10128',
                    coordinates: {
                      lat: '26.6617635',
                      lng: '-80.2683571',
                    },
                  },
                  timestamp: 1,
                },
                {
                  type: LoadRouteType.Drop,
                  event: {
                    status: LoadStatus.SCHEDULED,
                    timestamp: 4,
                    action: LoadEventAction.EDIT,
                  },
                  location: {
                    address: '45 Rockefeller Plaza',
                    city: 'New York',
                    state: 'NY',
                    zip: '10111',
                    coordinates: {
                      lat: '40.7587402',
                      lng: '-73.9786736',
                    },
                  },
                  timestamp: 4,
                },
              ],
            },
            info: {
              id: '278GF',
              size: LoadSize.LTL,
              milesLoaded: '500',
              type: LoadType.DryVan,
            },
            version: 2,
            files: {},
            accounting: {
              rate: '1200',
            },
          },
        ]}
        unitEvents={{
          1694290376321: {
            timestamp: 3,
            status: UnitStatus.FUEL,
            user: 'TInkG4Vy8nl2I0S27QCm',
            action: UnitEventAction.EDIT,
            location: {
              city: 'Miami', 
              state: 'FL',
              zip: 33142, 
              coordinates: {
                lat: 40.18435,
                lng: -74.70653,
              },
            },
            target: '7u3xBYhrQBXQzoU7MV9d',
          },
          1694790346707: {
            timestamp: -4,
            status: UnitStatus.FUEL,
            user: 'TInkG4Vy8nl2I0S27QCm',
            action: UnitEventAction.EDIT,
            location: {
              city: 'Brooklyn', 
              state: 'NY',
              zip: 11235, 
              coordinates: {
                lat: 40.18435,
                lng: -74.70653,
              },
            },
            target: '7u3xBYhrQBXQzoU7MV9d',
          },
        }}
      />
      <Grid container className="board">
        {shouldShowLoadTable && (
          <Grid item className="route" xs={5.5}>
            <Card>{generateAssignableTable}</Card>
          </Grid>
        )}
        <Grid item className="unit" xs={shouldShowLoadTable ? 6.5 : 12}>
          <Card>{generateTable}</Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(Units);

import { Connection, ConnectionType } from 'constants/Connection';
import { FieldType, FormConfig, FormType } from 'constants/form/Form';
import { Unit } from 'constants/Unit';

import { formatUnitSelect } from 'screens/dispatch/board/schemas/formatUnitValues';
import useTTEldAPI from 'services/eld/TTEld';

const ConfigName = 'Connection';

export const getConnectionAddFormConfig = (): FormConfig =>
  ({
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName} Setup`,
      content: [],
    },
  } as FormConfig);

const getGPSUnitsSettingsConfig = () => ({
  type: FormType.GENERAL,
  label: 'GPS',
  title: 'GPS Settings',
  content: [],
});

const getELDSettingsConfig = (values: any) => ({
  type: FormType.GENERAL,
  label: 'ELD',
  title: 'ELD Settings',
  content: values?.units?.map((unit: Unit) => [
    {
      name: `eld.${unit.id}.status`,
      label: 'Status',
      type: FieldType.STATUS,
      composition: {
        weight: 2,
      },
    },
    {
      name: `eld.${unit.id}.unit`,
      label: 'Unit',
      type: FieldType.TEXT,
      composition: {
        disabled: true,
        weight: 3,
      },
    },
    {
      name: `eld.${unit.id}.token`,
      label: `${formatUnitSelect(unit).name} Token`,
      type: FieldType.TEXT,
      control: {
        onChange: {
          target: `eld.${unit.id}.unit`,
          source: (sourceValue: any) => sourceValue,
          action: (target: any, token: any, setFieldValue: any) =>
            (token.length &&
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useTTEldAPI()
                .location(token)
                .then((result: any) => {
                  if (result) {
                    setFieldValue(`eld.${unit.id}.status`, 'success');
                    setFieldValue(target, result?.unit?.unitId ? `#${result.unit?.unitId}` : '');
                  }
                })
                .catch(() => setFieldValue(`eld.${unit.id}.status`, 'error'))) ||
            setFieldValue(`eld.${unit.id}.status`, 'inactive'),
        },
      },
      composition: {
        weight: 7,
      },
    },
  ]),
});

const getDrugSettingsConfig = () => ({
  type: FormType.GENERAL,
  label: 'Drug',
  title: 'Drug Settings',
  content: [],
});

const getConnecttionSettingsConfig = (values: Connection): FormConfig => {
  const results: any = {};

  values.type.forEach((type: ConnectionType) => {
    switch (type) {
      case ConnectionType.GPS:
        results[type] = getGPSUnitsSettingsConfig();
        break;
      case ConnectionType.ELD:
        results[type] = getELDSettingsConfig(values);
        break;
      case ConnectionType.Drug:
        results[type] = getDrugSettingsConfig();
        break;
      default:
        break;
    }
  });

  return results;
};

const getConnectionFormConfig = (values: Connection) =>
  values ? getConnecttionSettingsConfig(values) : getConnectionAddFormConfig();

export const connectionFormSetup = (id: any, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getConnectionFormConfig,
});

import { Icon, IconButton } from '@mui/material';

interface Props {
  actions: {
    dispatch?: () => void;
    code?: () => void;
    history?: () => void;
    edit?: () => void;
    delete?: () => void;
  };
}

function DataTableActionsCell({ actions }: Props): JSX.Element {
  return (
    <>
      {actions.dispatch && (
        <IconButton onClick={actions.dispatch}>
          <Icon fontSize="small" color="inherit">
            support_agent
          </Icon>
        </IconButton>
      )}
      {actions.code && (
        <IconButton onClick={actions.code}>
          <Icon fontSize="small" color="inherit">
            key
          </Icon>
        </IconButton>
      )}
      {actions.history && (
        <IconButton onClick={actions.history} disabled>
          <Icon fontSize="small" color="inherit">
            manage_search
          </Icon>
        </IconButton>
      )}
      {actions.edit && (
        <IconButton onClick={() => actions.edit!()}>
          <Icon fontSize="small" color="inherit">
            edit
          </Icon>
        </IconButton>
      )}
      {actions.delete && (
        <IconButton onClick={actions.delete}>
          <Icon fontSize="small" color="inherit">
            delete
          </Icon>
        </IconButton>
      )}
    </>
  );
}

export default DataTableActionsCell;

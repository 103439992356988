/* eslint-disable no-unused-vars */
import { Document, DocumentConfig } from 'constants/Document';
import { FieldType, FormConfig, FormTab, FormType } from 'constants/form/Form';
import {
  AddressSection,
  CityStateZipRow,
  CompanyNameRow,
  EmailFaxRow,
  PhoneExtensionRow,
  generateBankingTab,
  generateFilesTab,
  generateRolesSection,
} from 'constants/form/formConfig';
import { GroupStatus, GroupType } from 'constants/Group';
import { RoleType } from 'constants/Roles';

import { resolvePath } from 'components/BaseForm/Utils';
import { formatAssetSelect } from 'screens/roster/assets/formatAsset';
import useSJFactoringAPI from 'services/factoring/SJFactoringAPI';

const ConfigName = 'Group';

export enum GroupDocumentType {
  Contract = 'Contract',
  Misc = 'Misc',
}

export const GroupContract: Document = {
  name: 'Contract',
  issueTimestamp: 0,
  type: GroupDocumentType.Contract,
  role: {
    visibleFor: [RoleType.DRIVER, RoleType.ADMIN, RoleType.HR],
    requiredFor: [RoleType.DRIVER],
  },
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  version: 'New',
};

export const GroupMisc: Document = {
  name: 'Misc',
  issueTimestamp: 0,
  id: '',
  type: GroupDocumentType.Misc,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [],
  },
  version: 'New',
};

export const GroupDocumentConfig: DocumentConfig = {
  [GroupDocumentType.Contract]: GroupContract,
  [GroupDocumentType.Misc]: GroupMisc,
};

const generateMCRow = (values: any) => [
  {
    name: 'authority.type',
    label: 'MC/FF',
    type: FieldType.SELECT,
    options: ['MC', 'FF'],
    composition: {
      weight: 2,
      hideIf: (values: any) => resolvePath(values, 'type', '') !== GroupType.BROKER,
    },
  },
  {
    name: 'authority.number',
    label: 'Authority #',
    type: FieldType.TEXT,
    composition: {
      weight: 6,
      hideIf: (values: any) => resolvePath(values, 'type', '') !== GroupType.BROKER,
    },
    control: {
      onChange: {
        source: (value: any) => value,
        action: (target: any, source: any, setFieldValue: any) =>
          source?.length > 4 &&
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useSJFactoringAPI()
            .creditCheck(source, resolvePath(values, 'authority.type', 'MC'))
            .then((result) => {
              if (result) {
                setFieldValue('creditCheck', result.status);
                setFieldValue('companyName', result.companyName);
              }
            }),
      },
    },
  },
  {
    name: 'creditCheck',
    label: 'Credit Check',
    type: FieldType.STATUS,
    composition: {
      weight: 4,
      hideIf: (values: any) => resolvePath(values, 'type', '') !== GroupType.BROKER,
    },
  },
];

const generateRosterTab = (values: any): FormTab => ({
  type: FormType.GENERAL,
  label: 'Roster',
  title: `${ConfigName} Roster`,
  content: [
    [
      {
        name: 'members',
        label: 'Members',
        type: FieldType.SELECT,
        options: values?.members,
        multiple: true,
        control: {
          editable: 'showEmployeeForm',
          addable: 'showEmployeeForm',
        },
      },
    ],
    [
      {
        name: 'assets',
        label: 'Assets',
        type: FieldType.SELECT,
        options: values?.assets?.map((asset: any) => formatAssetSelect(asset)),
        multiple: true,
        control: {
          editable: 'showAssetForm',
          addable: 'showAssetForm',
        },
      },
    ],
  ],
});

export const getExtendedGroupsFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [CompanyNameRow, PhoneExtensionRow, EmailFaxRow, ...AddressSection],
    },
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName} Setup`,
      content: [
        [
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(GroupStatus),
            validation: {
              errorMsg: 'Status is required.',
            },
          },
        ],
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(GroupType),
            validation: {
              errorMsg: 'Type is required.',
            },
          },
        ],
        generateMCRow(values),
        [generateRolesSection()],
      ],
    },
    Roster: generateRosterTab(values),
    Banking: generateBankingTab(ConfigName),
    Files: generateFilesTab(ConfigName, GroupDocumentConfig, values),
  } as FormConfig);

export const getSimplifiedGroupsFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(GroupType),
            validation: {
              errorMsg: 'Type is required.',
            },
          },
        ],
        [generateRolesSection(true)],
        generateMCRow(values),
        CompanyNameRow,
        PhoneExtensionRow,
        EmailFaxRow,
        CityStateZipRow,
        ...(generateRosterTab(values).content as []),
      ],
    },
  } as FormConfig);

const getGroupsFormConfig = (values: any, extended: boolean) =>
  extended ? getExtendedGroupsFormConfig(values) : getSimplifiedGroupsFormConfig(values);

export const groupFormSetup = (id: any, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getGroupsFormConfig,
});

/* eslint-disable no-unused-vars */
enum ConnectionStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  HIDDEN = 'HIDDEN',
}

enum ConnectionEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

interface ConnectionEvent {
  timestamp: number;
  status: ConnectionStatus;
  note?: string;
  user?: string;
  action: ConnectionEventAction;
}

interface ConnecttioSettings {}

const ELDSettings: ConnecttioSettings = {
  units: [],
};

const GPSSettings: ConnecttioSettings = {
  assets: [],
};

const ConnecttioSettingsConfig = {
  ELD: ELDSettings,
  GPS: GPSSettings,
};

enum ConnectionProvider {
  TTELD = 'TTELD',
}

enum ConnectionType {
  ELD = 'ELD',
  GPS = 'GPS',
  Camera = 'Camera',
  Drug = 'Drug',
  Fuel = 'Fuel',
  Toll = 'Toll',
  MVR = 'MVR',
  PSP = 'PSP',
  Factoring = 'Factoring',
  Email = 'Email',
  Loadboard = 'Loadboard',
  OTHER = 'Other',
}

interface Connection {
  id: string;
  status: ConnectionStatus;
  type: ConnectionType[];
  version: number;
  events?: ConnectionEvent[];
  initialValues?: any;
  gps?: any;
  eld?: any;
  enabled: boolean;
  logo?: string;
  title: string;
  description: string;
  provider: ConnectionProvider;
}

export { ConnectionStatus, ConnectionEventAction, ConnectionType };
export type { Connection };

/* eslint-disable no-sequences */
import * as React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import FormField from 'screens/authentication/components/RegistrationCard/form/steps/FormField';

import { resolvePath } from '../Utils';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyB9BtkSrGfJpKOfp-tqRptbXbX9KfTVCTU';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoder = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
}

function GoogleAutocomplete(formData: any) {
  const {
    config,
    values,
    // validation: { errors, touched },
    actions,
  } = formData;

  const { label, name } = config;

  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&language=en&libraries=places,geocoding`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetchPlacePredictions = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string; componentRestrictions: { country: string } },
          callback: () => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(request, callback);
        },
        2000
      ),
    []
  );

  const fetchGeocode = React.useMemo(
    () => (request: { placeId?: string; address?: string }, callback: () => void) => {
      (geocoder.current as any).geocode(request, callback);
    },
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (!geocoder.current && (window as any).google) {
      geocoder.current = new (window as any).google.maps.Geocoder();
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);

      return undefined;
    }

    fetchPlacePredictions(
      { input: inputValue, componentRestrictions: { country: 'us' } },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchPlacePredictions]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.structured_formatting.main_text
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={resolvePath(values, `${config.name}.autocomplete`, null)}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);

        fetchGeocode(
          newValue?.place_id ? { placeId: newValue?.place_id } : { address: newValue?.description },
          (result?: any) => {
            if (result) {
              const address = result[0].address_components.reduce(
                (seed: any, { short_name, types }: any) => (
                  types.forEach((t: string) => (seed[t] = short_name)), seed
                ),
                {}
              );

              actions.setFieldValue(`${config.name}`, {
                placeId: { google: newValue?.place_id },
                coordinates: {
                  lat: result[0].geometry.location.lat(),
                  lng: result[0].geometry.location.lng(),
                },
                name: newValue?.structured_formatting.main_text,
                address:
                  address?.street_number && address?.route
                    ? `${address.street_number} ${address.route}`
                    : '',
                city: address?.locality || address?.sublocality || '',
                state: address?.administrative_area_level_1 || '',
                zip: address?.postal_code || '',
                autocomplete: newValue,
              });
            }
          }
        );

        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <FormField {...params} variant="standard" label={label} name={name} />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part: any, index: any) => (
                  <span
                    key={index + Math.random()}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default GoogleAutocomplete;

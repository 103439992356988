/* eslint-disable no-unused-vars */
import { AssetStatus, AssetType } from 'constants/Asset';
import { Document, DocumentConfig } from 'constants/Document';
import { FieldType, FormConfig, FormTab, FormType, usStates } from 'constants/form/Form';
import { generateFilesTab } from 'constants/form/formConfig';
import { RoleType } from 'constants/Roles';

import { vinRegExp } from 'components/BaseForm/Utils';
import useVehicleAPI from 'services/vin/VehicleAPI';

const ConfigName = 'Asset';

export enum AssetDocumentType {
  Contract = 'Contract',
  Misc = 'Misc',
}

export const AssetContract: Document = {
  name: 'Contract',
  issueTimestamp: 0,
  type: AssetDocumentType.Contract,
  role: {
    visibleFor: [RoleType.DRIVER, RoleType.ADMIN, RoleType.HR],
    requiredFor: [RoleType.DRIVER],
  },
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  version: 'New',
};

export const AssetMisc: Document = {
  name: 'Misc',
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.Misc,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [],
  },
  version: 'New',
};

export const AssetDocumentConfig: DocumentConfig = {
  [AssetDocumentType.Contract]: AssetContract,
  [AssetDocumentType.Misc]: AssetMisc,
};

const generateRosterTab = (values: any): FormTab => ({
  type: FormType.GENERAL,
  label: 'Roster',
  title: `${ConfigName} Roster`,
  content: [
    [
      {
        name: 'members',
        label: 'Members',
        type: FieldType.SELECT,
        options: values?.members,
        multiple: true,
        control: {
          editable: 'showEmployeeForm',
          addable: 'showEmployeeForm',
        },
      },
    ],
    [
      {
        name: 'assets',
        label: 'Assets',
        type: FieldType.SELECT,
        options: Object.values(values?.assets || []),
        multiple: true,
      },
    ],
  ],
});

export const getExtendedAssetsFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [],
    },
    Files: generateFilesTab(ConfigName, AssetDocumentConfig, values),
  } as FormConfig);

export const getSimplifiedAssetsFormConfig = (values: string): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        [
          {
            name: 'unitId',
            label: 'Unit Id',
            type: FieldType.TEXT,
            composition: {
              weight: 5,
            },
            validation: {
              errorMsg: 'Unit Id is required.',
            },
          },
          {
            name: 'plate',
            label: 'IRP/Plate',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
          {
            name: 'state',
            label: 'Base State',
            type: FieldType.SELECT,
            options: usStates,
            composition: {
              weight: 3,
            },
          },
        ],
        [
          {
            name: 'vin',
            label: 'VIN',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'VIN Id is required.',
              invalidMsg: 'VIN is not valid.',
              regex: vinRegExp,
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any) =>
                  source?.length === 17 &&
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useVehicleAPI()
                    .decodeVin(source)
                    .then((result) => {
                      if (result) {
                        setFieldValue('year', result.year);
                        setFieldValue('make', result.make);
                        setFieldValue('type', 'Truck');
                      }
                    }),
              },
            },
          },
        ],
        [
          {
            name: 'year',
            label: 'Year',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
          {
            name: 'make',
            label: 'Make',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(AssetType),
            composition: {
              weight: 6,
            },
          },
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(AssetStatus),
            composition: {
              weight: 6,
            },
          },
        ],
      ],
    },
  } as FormConfig);

const getAssetsFormConfig = (values: string, extended: boolean) =>
  extended ? getSimplifiedAssetsFormConfig(values) : getSimplifiedAssetsFormConfig(values);

export const assetFormSetup = (id: number, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getAssetsFormConfig,
});

import styled from 'styled-components';

export const StatusContainer = styled.div`
  width: 167px;
`;

export const StyledLine = styled.div<{ color?: string }>`
  width: 2px;
  height: 50px;
  background: ${({ color }) => (color ? color : 'transperant')};
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0 2px 11px;
  gap: 15px;
`;

export const StyledInfo = styled.div`
  position: absolute;
  right: -107px;
`;

export const StyledStatusName = styled.div`
  position: relative;
  padding-right: 3px;
  padding-top: 4px;
`;

export const StyledData = styled.span`
  position: absolute;
  bottom: -3px;
  font-size: 6px;
`;

export const StyledName = styled.p`
  font-weight: bold;
  font-size: 12px;
`;

import { Connection } from 'constants/Connection';

import withAuthorization from 'session/withAuthorization';

import DashboardLayout from 'components/containers/DashboardLayout';
import MDBox from 'components/common/MDBox';
import MDInput from 'components/common/MDInput';
import { Grid, Icon, IconButton } from '@mui/material';
import IntegrationCard from 'components/common/card/ProjectCards/IntegrationCard';
import useFirebaseConnectionAPI from 'services/data/Firebase/firebaseConnectionAPI';
import useBaseForm from 'hooks/useBaseForm';
import { useState } from 'react';

function Integrations(): JSX.Element {
  const { connections } = useFirebaseConnectionAPI();
  const { showConnectionForm } = useBaseForm();

  const [search, setSearch] = useState('');

  return (
    <DashboardLayout>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <MDBox display="flex" alignItems="center" width="12rem">
          <MDInput
            placeholder="Search by name or type..."
            value={search}
            size="small"
            fullWidth
            onChange={({ currentTarget }: any) => {
              setSearch(currentTarget.value);
            }}
          />
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <IconButton disabled={process.env.FEATURE_EMPLOYEE_EVENTS !== 'true'}>
            <Icon fontSize="small" color="inherit">
              manage_search
            </Icon>
          </IconButton>
          <IconButton disabled>
            <Icon fontSize="small" color="inherit">
              settings
            </Icon>
          </IconButton>
          <IconButton disabled onClick={() => showConnectionForm()}>
            <Icon fontSize="small">add_circle</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      <MDBox>
        <Grid container spacing={1}>
          {connections
            ?.filter(
              (connection: Connection) =>
                !search ||
                (search && connection.title?.toLowerCase().includes(search.toLowerCase())) ||
                (search &&
                  connection.type.some((type) => type.toLowerCase().includes(search.toLowerCase())))
            )
            ?.map((connection: Connection) => (
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <IntegrationCard
                  logo={connection.logo}
                  title={connection.title}
                  tags={connection.type}
                  description={connection.description}
                  enabled={connection.enabled}
                  menuItems={[
                    { label: 'Configuration', action: () => showConnectionForm(connection) },
                    { label: 'Settings', disabled: true },
                    { label: 'Test Connection', disabled: true },
                  ]}
                />
              </Grid>
            ))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(Integrations);

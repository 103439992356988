/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
export enum FormType {
  GENERAL,
  INFO,
  SETUP,
  FILES,
  ROUTE,
  ACCORDION,
  TIMELINE,
  UPDATE,
}

export enum FieldType {
  TEXT = 'text',
  NUMBER = 'number',
  SELECT = 'input',
  DATETIME = 'datetime-local',
  DATE = 'date',
  TIME = 'time',
  FILE = 'file',
  PHONE = 'phone',
  EMAIL = 'email',
  ROLES = 'roles',
  MAP = 'map',
  ACCORDION = 'accordion',
  GOOGLE_AUTOCOMPLETE = 'google_autocomplete',
  ACTIONS = 'actions',
  STATUS = 'status',
}
export enum ValidationType {
  STRING,
  ARRAY,
  OBJECT,
}

// Break up FormField per type
export interface FormField {
  name: string;
  label?: string;
  content?: string;
  type: FieldType;
  options?: any;
  placeholder?: string;
  multiple?: boolean;
  simplified?: boolean;
  validation?: {
    type?: ValidationType;
    errorMsg?: string;
    invalidMsg?: string;
    minMsg?: string;
    maxMsg?: string;
    regex?: RegExp;
    min?: number;
    max?: number;
    oneOf?: string[];
    nullable?: boolean;
  };
  composition?: {
    disabled?: boolean;
    weight?: number;
    style?: any;
    vertical?: boolean;
    hideIf?: any;
  };
  control?: {
    formatBy?: any;
    target?: any;
    source?: any;
    onChange?: any;
    editable?: any;
    addable?: any;
    actions?: any;
  };
  select?: {
    infoArray?: any;
    multiple?: boolean;
    groupBy?: any;
  };
}

export interface FormConfig {
  [key: string]: FormTab;
}

export interface FormTab {
  label: string;
  type: FormType;
  title?: string;
  content: FormConfig | FormField[][] | FormField[][][];
  icon?: any;
  config?: any;
}

export const usStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

/* eslint-disable react/destructuring-assignment */
import { FormConfig, FormType } from 'constants/form/Form';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Grid, Icon, IconButton, Switch, useMediaQuery, useTheme } from '@mui/material';
import MDBox from 'components/common/MDBox';
import MDTypography from 'components/common/MDTypography';
import { SyntheticEvent, useState } from 'react';

import VerTab from 'components/CustomComponents/VerTab/verTab';

import RouteAccordion from '../sections/RouteAccordion';

import TimelineSection from '../sections/TimelineSection';

import BaseForm from './BaseTab';
import SideTabs from './SideTabs';

interface MainTabsProps {
  mainConfig: FormConfig;
  formValues: { values: any; initialTabs: any };
  validation: { touched: any; errors: any };
  actions: {
    setFieldValue: any;
    setFieldTouched: any;
    tabActions?: { setSelectedTypeTab: any; setSelectedVersionTab: any };
    dispatchActions?: any;
    form: { extendedForm: any; setExtendedForm: any };
  };
}

function MainTabs({ mainConfig, formValues, validation, actions }: MainTabsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { values, initialTabs } = formValues;
  const { setFieldValue, dispatchActions } = actions;

  const mainTabs: string[] = Object.keys(mainConfig);
  const [mainTab, setMainTab] = useState<string>(initialTabs?.main || mainTabs[0]);
  const [selectedTypeTab, setSelectedTypeTab] = useState<string>(initialTabs?.option);
  const [selectedVersionTab, setSelectedVersionTab] = useState<string>(initialTabs?.version);

  const onMainTabChange = (_event: SyntheticEvent, newValue: string) => {
    setMainTab(newValue);
  };

  const handleInitialTab = (typeTab: string, versionTab: string) => {
    const mainKey = mainTab.toLowerCase();

    if (typeTab && versionTab && !values[mainKey]) {
      setFieldValue(`${mainKey}.${[typeTab]}.${versionTab}`, {
        version: versionTab,
        name: typeTab,
      });
    }
  };

  const handleAddRoute = () => {
    const mainKey = mainTab.toLowerCase();
    values[mainKey].stops.push({ type: 'Drop', location: '', timestamp: '' });
    setFieldValue(`${mainKey}.stops`, values[mainTab.toLowerCase()].stops);
  };

  const handleAddTab = (typeTab: string = selectedTypeTab) => {
    const mainKey = mainTab.toLowerCase();
    // ADD LOGIC FOR CUSTOM VERSIONING
    const newVersion =
      values[mainKey] && values[mainKey][typeTab]
        ? parseInt(Object.keys(values[mainKey][typeTab]).reverse()[0], 10) + 1
        : 0;

    let newTypeFile = {
      [newVersion]: {
        version: newVersion,
        name: typeTab,
      },
    };

    if (!values[mainKey][typeTab]) {
      newTypeFile = {
        [selectedVersionTab]: {
          version: selectedVersionTab,
          name: typeTab,
        },
        ...newTypeFile,
      };
    }

    setFieldValue(`${mainKey}.${[typeTab]}`, newTypeFile);
  };

  const handleResetTab = (
    path: string = `${mainTab.toLowerCase()}.${[selectedTypeTab]}.${selectedVersionTab}`,
    defaultValue: any = {
      version: selectedVersionTab,
      name: selectedTypeTab,
    }
  ) => {
    setFieldValue(path, defaultValue);
  };

  const handleDeleteTab = () => {
    const mainKey = mainTab.toLowerCase();

    if (Object.keys(values[mainKey][selectedTypeTab]).length > 1) {
      delete values[mainKey][selectedTypeTab][selectedVersionTab];

      setFieldValue(
        `${mainKey}.${[selectedTypeTab]}`,
        values[mainKey][selectedTypeTab].filter((item: any) => item === null)
      );
    }
  };

  const handleAddUpdate = () => {
    if (values?.load) {
      dispatchActions(values.load);
    }
  };

  const renderMainTab = (mainTabConfig: any) => {
    if (mainTabConfig.type === FormType.ROUTE) {
      return (
        <RouteAccordion
          config={mainTabConfig.content}
          values={formValues.values}
          validation={validation}
          actions={actions}
        />
      );
    }

    if (mainTabConfig.type === FormType.TIMELINE) {
      return (
        <TimelineSection
        // config={mainTabConfig.content}
        // values={formValues.values}
        // validation={validation}
        // actions={actions}
        />
      );
    }

    if (Array.isArray(mainTabConfig.content)) {
      return (
        <BaseForm
          config={mainTabConfig.content}
          formValues={formValues}
          validation={validation}
          actions={actions}
        />
      );
    }

    return (
      <SideTabs
        typeConfig={mainConfig[mainTab].content as FormConfig}
        formValues={formValues}
        validation={validation}
        actions={{
          ...actions,
          tabActions: { setSelectedTypeTab, setSelectedVersionTab, handleInitialTab },
        }}
      />
    );
  };

  const generateControlTab = (type: FormType) => {
    switch (type) {
      case FormType.FILES:
        return (
          <Grid item xs="auto" justifyContent="space-between">
            <IconButton onClick={() => handleAddTab()}>
              <Icon fontSize="small" color="inherit">
                post_add
              </Icon>
            </IconButton>
            <IconButton onClick={() => handleResetTab()}>
              <Icon fontSize="small" color="inherit">
                replay
              </Icon>
            </IconButton>
            <IconButton onClick={handleDeleteTab}>
              <Icon fontSize="small" color="inherit">
                close
              </Icon>
            </IconButton>
          </Grid>
        );
      case FormType.ROUTE:
        return (
          <Grid item xs="auto" justifyContent="space-between">
            <IconButton onClick={handleAddRoute}>
              <Icon fontSize="small" color="inherit">
                add_road
              </Icon>
            </IconButton>
            <IconButton>
              <Icon fontSize="small" color="inherit">
                replay
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
          </Grid>
        );
      case FormType.TIMELINE:
        return (
          <Grid item xs="auto" justifyContent="space-between">
            <IconButton disabled={!values?.load} onClick={handleAddUpdate}>
              <Icon fontSize="small" color="inherit">
                support_agent
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                replay
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
          </Grid>
        );
      default:
        return <div />;
    }
  };

  const handleTabChange = (tab: string) => {
    setSelectedTypeTab(tab);
    setMainTab(tab); // Use the setTypeTab function
  };
  
  return (
    <Grid item xs>
      <TabContext value={mainTab}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: isMobile ? 1 : 2, pb: 0 }}
        >
          {(mainTabs.length > 1 && (
            <TabList onChange={onMainTabChange} variant="scrollable" scrollButtons="auto">
              {mainTabs.length > 1 &&
                mainTabs.map((maintTab) => (
                  <VerTab
                    key={maintTab}
                    label={mainConfig[maintTab].label}
                    disableRipple
                    value={[mainConfig[maintTab].label]}
                    activeTab={mainTab}
                    onTabChange={handleTabChange}
                  />
                ))}
            </TabList>
          )) || (
            <MDTypography sx={{ p: 1 }} variant="h5">
              {mainConfig[mainTab].title}
            </MDTypography>
          )}
          {mainTabs.length > 1 ? 
          <MDTypography style={{display: 'flex', marginLeft: 'auto', fontSize: '11px', fontWeight: 'bold'}}>EXTENDED</MDTypography> : 
          <MDTypography style={{display: 'flex', marginLeft: 'auto', fontSize: '11px', fontWeight: 'bold'}}>SIMPLE</MDTypography>}
          <div>
            <Switch
              checked={actions.form.extendedForm}
              onChange={() => {
                setMainTab(mainTabs[0]);
                actions.form.setExtendedForm(!actions.form.extendedForm);
              }}
            />
            {!isMobile && (
              <IconButton
                size="small"
                disableRipple
                style={{ cursor: 'move' }}
                id="draggable-dialog"
              >
                <Icon>open_with</Icon>
              </IconButton>
            )}
          </div>
        </MDBox>
        {mainTabs.map((mainTab) => (
          // eslint-disable-next-line react/jsx-key
          <TabPanel
            value={(mainConfig as FormConfig)[mainTab].label}
            sx={{ p: isMobile ? 1 : 2, pt: 0 }}
          >
            <>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                xs={12}
                sx={{ p: 1, px: 0 }}
                alignItems="center"
              >
                {mainTabs.length > 1 ? (
                  <MDTypography variant="h3">{mainConfig[mainTab].title}</MDTypography>
                ) : null}
                {generateControlTab(mainConfig[mainTab]?.type)}
              </Grid>
              {mainConfig[mainTab].content && renderMainTab(mainConfig[mainTab])}
            </>
          </TabPanel>
        ))}
      </TabContext>
    </Grid>
  );
}

export default MainTabs;

import { RoleType } from 'constants/Roles';

import React from 'react';
import { Icon, IconButton } from '@mui/material';
import MDBox from 'components/common/MDBox';

interface Props {
  row: any;
  actions: any;
}

const getIconByRole = (role: string): string => {
  switch (role) {
    case RoleType.ADMIN:
      return 'settings';
    case RoleType.ACCOUNTANT:
      return 'request_quote_outlined';
    case RoleType.DRIVER:
      return 'local_shipping_outlined';
    case RoleType.DISPATCHER:
      return 'explore';
    case RoleType.HR:
      return 'people_alt';
    case RoleType.SAFETY:
      return 'shield';
    case RoleType.SERVICE:
      return 'build';
    default:
      return '';
  }
};

function DataTableRolesCell({ row, actions }: Props): JSX.Element {
  return (
    <MDBox display="flex" justifyContent="space-between">
      {row?.roles &&
        Object.keys(row.roles).map((role: string) => (
          <React.Fragment key={getIconByRole(role)}>
            <IconButton
              disableRipple
              onClick={() => {
                actions.edit(row, { main: 'Setup' });
              }}
            >
              <Icon fontSize="small" color="inherit">
                {getIconByRole(role)}
              </Icon>
            </IconButton>
          </React.Fragment>
        ))}
    </MDBox>
  );
}

export default DataTableRolesCell;

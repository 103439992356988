/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from '@mui/material/Icon';

// Routes
// import Dashboard from "screens/dashboard";

import ROUTES from 'navigation/const';

// DISPATCH screens
import Board from 'screens/dispatch/board';

// FREIGHT screens
import Loads from 'screens/freight/loads';

// import Factoring from "screens/accounting/factoring";
// import Profile from "screens/accounting/profile";
// import Statements from "screens/accounting/statements";

// import Account from "screens/connections/accounts";
import Integrations from 'screens/connections/integrations';

// ROSTER screens
import Employees from 'screens/roster/employees';
import Groups from 'screens/roster/groups';
import Assets from 'screens/roster/assets';

// import Account from "screens/authentication/components/RegistrationCard/form/steps/Account";

// import Statement from "screens/authentication/components/RegistrationCard/form/steps/Roles";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   route: ROUTES.DASHBOARD,
  //   component: <Dashboard />,
  //   noCollapse: true,
  // },
  {
    type: 'collapse',
    name: 'Dispatch',
    key: 'dispatch',
    route: ROUTES.DISPATCH + ROUTES.BOARD,
    component: <Board />,
    icon: <Icon fontSize="medium">support_agent</Icon>,
    noCollapse: true,
    // collapse: [
    //   {
    //     name: "Board",
    //     key: "board",
    //     route: ROUTES.DISPATCH + ROUTES.BOARD,
    //     component: <Board />,
    //   },
    //   {
    //     name: "Units",
    //     key: "units",
    //     route: ROUTES.ACCOUNTING + ROUTES.PROFILE,
    //     component: <Profile />,
    //   },
    // ],
  },
  {
    type: 'collapse',
    name: 'Freight',
    key: 'freight',
    icon: <Icon fontSize="medium">inventory_2</Icon>,
    route: ROUTES.FREIGHT + ROUTES.LOADS,
    component: <Loads />,
    noCollapse: true,
    // collapse: [
    //   {
    //     name: "Loads",
    //     key: "loads",
    //     route: ROUTES.FREIGHT + ROUTES.LOADS,
    //     component: <Loads />,
    //   },
    //   {
    //     name: "Loadboard",
    //     key: "loadboaard",
    //     route: ROUTES.ACCOUNTING + ROUTES.PROFILE,
    //     component: <Profile />,
    //   },
    // ],
  },
  // {
  //   type: "collapse",
  //   name: "Accounting",
  //   key: "accounting",
  //   icon: <Icon fontSize="medium">account_balance_wallet</Icon>,
  //   collapse: [
  //     {
  //       name: "Statements",
  //       key: "statements",
  //       route: ROUTES.ACCOUNTING + ROUTES.STATEMENTS,
  //       component: <Statements />,
  //     },
  //     {
  //       name: "Factoring",
  //       key: "factoring",
  //       route: ROUTES.ACCOUNTING + ROUTES.FACTORING,
  //       component: <Factoring />,
  //     },
  //     {
  //       name: "Profiles",
  //       key: "profiles",
  //       route: ROUTES.ACCOUNTING + ROUTES.PROFILE,
  //       component: <Profile />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Safety",
  //   key: "safety",
  //   icon: <Icon fontSize="medium">shield</Icon>,
  //   collapse: [
  //     {
  //       name: "Overview",
  //       key: "overview",
  //       route: ROUTES.CONNECTIONS + ROUTES.ACCOUNTS,
  //       component: <Account />,
  //     },
  //     {
  //       name: "Inspections",
  //       key: "inspections",
  //       route: ROUTES.CONNECTIONS + ROUTES.ACCOUNTS,
  //       component: <Account />,
  //     },
  //     {
  //       name: "Accidents",
  //       key: "accidents",
  //       route: ROUTES.CONNECTIONS + ROUTES.INTEGRATIONS,
  //       component: <Integrations />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Fleet",
  //   key: "fleet",
  //   icon: <Icon fontSize="medium">build</Icon>,
  //   collapse: [
  //     {
  //       name: "Map",
  //       key: "map",
  //       route: ROUTES.CONNECTIONS + ROUTES.ACCOUNTS,
  //       component: <Account />,
  //     },
  //     {
  //       name: "Reports",
  //       key: "reports",
  //       route: ROUTES.CONNECTIONS + ROUTES.INTEGRATIONS,
  //       component: <Integrations />,
  //     },
  //     {
  //       name: "Work Orders",
  //       key: "workorders",
  //       route: ROUTES.CONNECTIONS + ROUTES.INTEGRATIONS,
  //       component: <Integrations />,
  //     },
  //   ],
  // },
  {
    type: 'collapse',
    name: 'Connections',
    key: 'connections',
    icon: <Icon fontSize="medium">hub</Icon>,
    route: ROUTES.CONNECTIONS + ROUTES.INTEGRATIONS,
    component: <Integrations />,
    noCollapse: true,
    // collapse: [
    //   {
    //     name: "Accounts",
    //     key: "accounts",
    //     route: ROUTES.CONNECTIONS + ROUTES.ACCOUNTS,
    //     component: <Account />,
    //   },
    //   {
    //     name: "Integrations",
    //     key: "integrations",
    //     route: ROUTES.CONNECTIONS + ROUTES.INTEGRATIONS,
    //     component: <Integrations />,
    //   },
    //   {
    //     name: "Rules",
    //     key: "rules",
    //     route: ROUTES.CONNECTIONS + ROUTES.INTEGRATIONS,
    //     component: <Integrations />,
    //   },
    // ],
  },
  {
    type: 'collapse',
    name: 'Roster',
    key: 'roster',
    icon: <Icon fontSize="medium">list_alt</Icon>,
    collapse: [
      {
        name: 'Employees',
        key: 'employees',
        route: ROUTES.ROSTER + ROUTES.EMPLOYEES,
        component: <Employees />,
      },
      {
        name: 'Assets',
        key: 'assets',
        route: ROUTES.ROSTER + ROUTES.ASSETS,
        component: <Assets />,
      },
      {
        name: 'Groups',
        key: 'groups',
        route: ROUTES.ROSTER + ROUTES.GROUPS,
        component: <Groups />,
      },
    ],
  },
];

export default routes;

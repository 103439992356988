import { Employee } from 'constants/Employee';
import { Group } from 'constants/Group';
import { Load } from 'constants/Load';
import { difference } from 'constants/Util';

import { arrayRemove, arrayUnion } from 'firebase/firestore';

function updatedDependentFields(
  initialValues: any,
  submittedValues: any,
  firestore: any,
  company: any
) {
  const batch = firestore.batch();

  const addedValues: any = difference(initialValues, submittedValues);
  const removedValues: any = difference(submittedValues, initialValues);

  console.log('updatedDependentFields', addedValues, removedValues);
  // TODO REMOVE/ADD ASSETS ON GROUP

  // Add Employee to selected Groups
  addedValues?.groups?.forEach((group: Group) =>
    batch.update(firestore.doc(`company/${company.companyId}/groups/${group.id}`), {
      members: arrayUnion({ id: submittedValues.id }),
    })
  );

  // Remove Employee from unselected Groups
  removedValues?.groups?.forEach((group: Group) =>
    batch.update(firestore.doc(`company/${company.companyId}/groups/${group.id}`), {
      members: arrayRemove({ id: initialValues.id }),
    })
  );

  // Add Employee on selected Unit
  if (addedValues?.unit) {
    batch.update(firestore.doc(`company/${company.companyId}/units/${addedValues.unit.id}`), {
      drivers: arrayUnion({ id: submittedValues.id }),
    });
  }

  // Remove Employee from unselected Unit
  if (removedValues?.unit) {
    batch.update(firestore.doc(`company/${company.companyId}/units/${removedValues.unit.id}`), {
      drivers: arrayRemove({ id: initialValues.id }),
    });
  }

  // Add Group to selected Employees
  addedValues?.members?.forEach((member: Employee) =>
    batch.update(firestore.doc(`company/${company.companyId}/employees/${member.id}`), {
      groups: arrayUnion({ id: submittedValues.id }),
    })
  );

  // Remove Group from unselected Employees
  removedValues?.members?.forEach((member: Employee) =>
    batch.update(firestore.doc(`company/${company.companyId}/employees/${member.id}`), {
      groups: arrayRemove({ id: initialValues.id }),
    })
  );

  // Add Load on selected Unit
  if (addedValues?.assignedTo) {
    batch.update(firestore.doc(`company/${company.companyId}/units/${addedValues.assignedTo.id}`), {
      loads: arrayUnion({ id: submittedValues.id }),
    });
  }

  // Remove Load from unselected Unit
  if (removedValues?.assignedTo) {
    batch.update(
      firestore.doc(`company/${company.companyId}/units/${removedValues.assignedTo.id}`),
      {
        loads: arrayRemove({ id: initialValues.id }),
      }
    );
  }

  // Move Unit to selected Loads
  addedValues?.loads?.forEach((load: Load) => {
    batch.update(firestore.doc(`company/${company.companyId}/loads/${load.id}`), {
      assignedTo: { id: submittedValues.id },
    });

    // Remove Load from old Unit
    if (load.assignedTo?.id) {
      batch.update(firestore.doc(`company/${company.companyId}/units/${load.assignedTo.id}`), {
        loads: arrayRemove({ id: submittedValues.id }),
      });
    }
  });

  // Remove Unit from unselected Loads
  removedValues?.loads?.forEach((load: Load) =>
    batch.update(firestore.doc(`company/${company.companyId}/loads/${load.id}`), {
      assignedTo: null,
    })
  );

  // Move Unit to selected Driver
  addedValues?.drivers?.forEach((driver: Employee) => {
    batch.update(firestore.doc(`company/${company.companyId}/employees/${driver.id}`), {
      unit: { id: submittedValues.id },
    });

    // Remove Driver from old Unit
    if (driver.unit?.id) {
      batch.update(firestore.doc(`company/${company.companyId}/units/${driver.unit.id}`), {
        drivers: arrayRemove({ id: driver.id }),
      });
    }
  });

  // Remove Unit from unselected Driver
  removedValues?.drivers?.forEach((driver: Employee) =>
    batch.update(firestore.doc(`company/${company.companyId}/employees/${driver.id}`), {
      unit: null,
    })
  );

  if (addedValues?.truck?.id) {
    batch.update(firestore.doc(`company/${company.companyId}/assets/${addedValues.truck.id}`), {
      unit: { id: submittedValues.id },
    });

    if (addedValues.truck?.unit?.id) {
      batch.update(
        firestore.doc(`company/${company.companyId}/units/${addedValues.truck?.unit?.id}`),
        {
          truck: null,
        }
      );
    }
  }

  if (removedValues?.truck?.id) {
    batch.update(firestore.doc(`company/${company.companyId}/assets/${removedValues.truck.id}`), {
      unit: null,
    });
  }

  if (addedValues?.trailer?.id) {
    batch.update(firestore.doc(`company/${company.companyId}/assets/${addedValues.trailer.id}`), {
      unit: { id: submittedValues.id },
    });

    if (addedValues.trailer?.unit?.id) {
      batch.update(
        firestore.doc(`company/${company.companyId}/units/${addedValues.trailer?.unit?.id}`),
        {
          trailer: null,
        }
      );
    }
  }

  if (removedValues?.trailer?.id) {
    batch.update(firestore.doc(`company/${company.companyId}/assets/${removedValues.trailer.id}`), {
      unit: null,
    });
  }

  return batch.commit();
}

export default updatedDependentFields;

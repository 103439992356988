
import { Document, DocumentFile } from 'constants/Document';
import { FormField } from 'constants/form/Form';

import { useState } from 'react';

import { Document as PDFDocument, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import Grid from '@mui/material/Grid';
import { Icon, IconButton } from '@mui/material';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/common/MDBox';
import MDButton from 'components/common/MDButton';
import MDInput from 'components/common/MDInput';
import MDProgress from 'components/common/MDProgress';
import uploadPlaceholderImage from 'assets/images/file-preview.png';
import './FileUpload.css';

import { resolvePath } from '../Utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface FileUploadProps {
  fieldConfig: FormField;
  fileValues: Document;
  validation: { errors: any; touched: any };
  actions: {
    fileActions: any;
    setFieldValue: any;
  };
}

function FileUpload({ fieldConfig, fileValues, validation, actions }: FileUploadProps) {
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  // const updateProgres = (progress: number) => setUploadProgress(progress);

  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
    setPageNumber(1);
  };

  const onPreviewClick = () => console.log('Showing Preview!', validation);

  const onPageChange = (offset: any) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => onPageChange(-1);

  const nextPage = () => onPageChange(1);

  // const onFileReset = () => {
  //   actions.setFieldValue(`${fieldConfig.name}.file.content`, undefined);
  //   actions.setFieldValue(`${fieldConfig.name}.file.uploadTimestamp`, Date.now());
  // };

  // const onFileUpload = (file: DocumentFile) =>
  //   actions.fileActions
  //     ?.upload(
  //       {
  //         content: file.content,
  //         extension: file.extension,
  //         name: file.name,
  //         path: `/${employeeId}/${[type]}/`,
  //         version: tab,
  //       },
  //       updateProgres
  //     )
  //     .then((url: string) => {
  //       actions.setFieldValue(`${fieldConfig.name}.file.url`, url);
  //       onFileReset();
  //     });

  const onFileDelete = (url: string) =>
    actions.fileActions
      ?.delete(url)
      .then(() => actions.setFieldValue(`${fieldConfig.name}.file.content`, undefined));

  const onFileChange = (event: any) => {
    const file = event.target.files[0];
    actions.setFieldValue(`${event.target.name}.file.extension`, file.type);
    actions.setFieldValue(`${event.target.name}.file.content`, file);
    actions.setFieldValue(`${event.target.name}.file.name`, file.name);
    setUploadProgress(0);
  };

  const isImage = (extension: string) =>
    extension === 'image/jpg' ||
    extension === 'image/jpeg' ||
    extension === 'image/png' ||
    extension === 'image/gif';

  const isEmptyFile = (file: any) => file === undefined || file === null || file === '';

  const renderImageSrc = (file: DocumentFile | undefined) =>
    (file && file.content && URL.createObjectURL(file.content)) ||
    (file && file.url) ||
    uploadPlaceholderImage;

  const renderImagePreview = (file: DocumentFile | undefined) => (
    <Grid item xs={12}>
      <MDBox component="img" width="100%" src={renderImageSrc(file)} alt="new" />
    </Grid>
  );

  const renderInputSection = (fileVersion: Document) => (
    <Grid container item justifyContent="space-between" alignItems="center" xs={12}>
        <MDInput
          className = 'bbb'
          name={fieldConfig.name}
          type="file"
          onChange={(event: any) => onFileChange(event)}
          key={fileVersion?.file?.uploadTimestamp || 0}
        />
        <IconButton disabled>
          <Icon fontSize="small" color="inherit">
            psychology
          </Icon>
        </IconButton> 
        <IconButton
          disabled={!fileVersion?.file?.content}
          // onClick={() => onFileUpload(resolvePath(fileValues, fieldConfig.name, {}))}
        >
          <Icon fontSize="small" color="inherit">
            upload
          </Icon>
        </IconButton>
        <IconButton
          disabled={!fileVersion?.file?.url}
          onClick={() => onFileDelete(resolvePath(fileValues, `${fieldConfig.name}.file.url`, ''))}
        >
          <Icon fontSize="small" color="inherit">
            delete
          </Icon>
        </IconButton>
    </Grid>
  );

  const renderPDFPreview = (file: DocumentFile) => (
    <>
      <Grid item xs={12}>
        <PDFDocument
          file={file.content || { url: `${file.url}` }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page width={350} scale={1} pageNumber={pageNumber} onClick={() => onPreviewClick()} />
        </PDFDocument>
      </Grid>
      {numPages > 1 && (
        <Grid container item xs={12} justifyContent="space-around" direction="row" sx={{ mb: 1 }}>
          <MDButton
            variant="gradient"
            color="light"
            disabled={pageNumber <= 1}
            onClick={previousPage}
            size="small"
          >
            Previous
          </MDButton>
          <MDButton
            variant="gradient"
            disabled={pageNumber >= numPages}
            color="light"
            onClick={nextPage}
            size="small"
          >
            Next
          </MDButton>
        </Grid>
      )}
    </>
  );

  const renderPreview = (file: DocumentFile | undefined) =>
    !file || (isEmptyFile(file.content) && !file.url) || isImage(file.extension)
      ? renderImagePreview(file)
      : renderPDFPreview(file);

  return (
    <>
      {fieldConfig && <MDProgress value={uploadProgress} />}
      {renderPreview(resolvePath(fileValues, `${fieldConfig.name}.file`, null))}
      {renderInputSection(resolvePath(fileValues, fieldConfig.name, ''))}
    </>
  );
}

export default FileUpload;

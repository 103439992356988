/* eslint-disable react/destructuring-assignment */
import { FormConfig, FormField } from 'constants/form/Form';

import { SyntheticEvent, useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import MDBox from 'components/common/MDBox';

import NewTab from 'components/CustomComponents/Tab/tab';

import VersionTabs from './VersionTabs';
import BaseForm from './BaseTab';

import './BaseTab.css';

interface SideTabsProps {
  typeConfig: FormConfig;
  formValues: { values: any; initialTabs: any };
  validation: { touched: any; errors: any };
  actions: {
    setFieldValue: any;
    tabActions: { setSelectedTypeTab: any; setSelectedVersionTab: any; handleInitialTab: any };
  };
}

function SideTabs({ typeConfig, formValues, validation, actions }: SideTabsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const typeTabs = Object.keys(typeConfig);
  const [typeTab, setTypeTab] = useState<string>(formValues?.initialTabs?.options || typeTabs[0]);

  const {
    tabActions: { setSelectedTypeTab },
  } = actions;

  useEffect(() => {
    setSelectedTypeTab(typeTab);
  }, []);

  const onSideTabChange = (_event: SyntheticEvent, newValue: string) => {
    setSelectedTypeTab(newValue);
    setTypeTab(newValue);
  };


  const handleTabChange = (tab: string) => {
    setSelectedTypeTab(tab);
    setTypeTab(tab);
  };

  const renderSideTab = (config: FormConfig | FormField[][] | FormField[][][]) =>
    Array.isArray(config) ? (
      <BaseForm config={config} formValues={formValues} validation={validation} actions={actions} />
    ) : (
      <VersionTabs
        versionConfig={config}
        formValues={formValues}
        validation={validation}
        actions={actions}
      />
    );

    return (
      <MDBox>
        <Grid container columnSpacing={2}>
          <TabContext value={typeTab}>
            <Grid item xs={12} sm={3}>
              <TabList onChange={onSideTabChange} orientation={isMobile ? 'horizontal' : 'vertical'}>
                {typeTabs.map((typeKey) => (
                  <NewTab
                    key={typeKey}
                    tabs={[typeConfig[typeKey].label]}
                    activeTab={typeTab}
                    onTabChange={handleTabChange}
                  />
                ))}
              </TabList>
            </Grid>
            <Grid item xs={12} sm={9}>
              {typeTabs.map((typeKey) => (
                <TabPanel value={typeKey} key={typeKey} sx={{ p: 0 }}>
                  {typeConfig[typeKey].content && renderSideTab(typeConfig[typeKey].content)}
                </TabPanel>
              ))}
            </Grid>
          </TabContext>
        </Grid>
      </MDBox>
    );
}

export default SideTabs;

import { FieldType, FormTab } from 'constants/form/Form';

import { Grid } from '@mui/material';

import MDBadge from 'components/common/MDBadge';

import { Form } from 'formik';

import FileUpload from '../fields/FileUpload';
import InputField from '../fields/InputField';
import SelectField from '../fields/SelectField';

import RolesSection from '../sections/RoleSection';
import GoogleAutocomplete from '../fields/GoogleAutocomplete';
import ActionSection from '../sections/ActionSection';
import DateField from '../fields/DateField';
import MapboxStaticMapField from '../fields/StaticMapField';
import { resolvePath } from '../Utils';
import  './BaseTab.css'

function StatusBadge({ config: { name }, values }: any) {
  const status = resolvePath(values, name, 'inactive');
  const statusColor: any = {
    inactive: 'info',
    pending: 'info',
    error: 'error',
    declined: 'error',
    success: 'success',
    approved: 'success',
    warning: 'warning',
  };

  return (
    <Grid container item justifyContent="center" alignItems="center" alignContent="center">
      <MDBadge
        variant="contained"
        size="md"
        badgeContent={status}
        color={statusColor[status]}
        container
      />
    </Grid>
  );
}

function BaseForm({
  config,
  formValues: { values },
  validation: { touched, errors },
  actions,
}: any): JSX.Element {
  const renderFiedByType = (field: any) => {
    switch (field.type) {
      case FieldType.TEXT:
      case FieldType.PHONE:
      case FieldType.EMAIL:
      case FieldType.NUMBER:
        return (
          <Form className='form-main'>
          <InputField
            className='field'
            config={field}
            values={values}
            validation={{ errors, touched }}
            actions={actions}
          />
          </Form>
        );
      case FieldType.SELECT:
        return (
          <SelectField
            config={field}
            values={values}
            validation={{ errors, touched }}
            actions={actions}
          />
        );
      case FieldType.ROLES:
        return RolesSection({ field, roles: values[field.name] || {}, actions });
      case FieldType.FILE:
        return (
          <FileUpload
            fieldConfig={field}
            fileValues={values}
            validation={{ errors, touched }}
            actions={actions}
          />
        );
      case FieldType.DATE:
      case FieldType.TIME:
      case FieldType.DATETIME:
        return (
          <DateField
            config={field}
            values={values}
            validation={{ errors, touched }}
            actions={actions}
          />
        );
      case FieldType.GOOGLE_AUTOCOMPLETE:
        return (
          <GoogleAutocomplete
            config={field}
            values={values}
            validation={{ errors, touched }}
            actions={actions}
          />
        );
      case FieldType.ACTIONS:
        return (
          <ActionSection
            config={field}
            values={values}
            validation={{ errors, touched }}
            actions={actions}
          />
        );
      case FieldType.MAP:
        return <MapboxStaticMapField config={field} values={values} actions={actions} />;
      case FieldType.STATUS:
        return <StatusBadge config={field} values={values} />;
      default:
        return null;
    }
  };

  const renderField = (field: any) => {
    const { composition } = field;

    return (
      <Grid item xs={composition?.weight ? composition.weight : 12}>
        {(!field?.composition?.hideIf || !field?.composition?.hideIf(values)) &&
          renderFiedByType(field)}
      </Grid>
    );
  };

  const renderRow = (value: any) => {
    if (value instanceof Array) {
      return value.map((field) => renderField(field));
    }

    return renderField(value);
  };

  return (
    <Grid container direction="column">
      {config.map((value: FormTab | FormTab[]) => (
        <Grid container columnSpacing={1} direction="row" sx={{ p: 0 }} alignItems="center">
          {renderRow(value)}
        </Grid>
      ))}
    </Grid>
  );
}

export default BaseForm;

import withAuthorization from 'session/withAuthorization';

import Card from '@mui/material/Card';

import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import { Box, Icon, IconButton } from '@mui/material';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import { useDeepCompareMemo } from 'use-deep-compare';
import useBaseForm from 'hooks/useBaseForm';
import useFirebaseAssetAPI from 'services/data/Firebase/FirebaseAssetAPI';
import AssetsCard from 'components/AssetsCard';
import { useState } from 'react';

const assetTable = {
  columns: [
    { Header: 'unit', accessor: 'unitId', width: '15%' },
    { Header: 'type', accessor: 'type', width: '10%' },
    { Header: 'status', accessor: 'status', width: '10%' },
    { Header: 'vin', accessor: 'vin', width: '15%' },
    { Header: 'plate', accessor: 'plate', width: '15%' },
    {
      Header: 'info',
      accessor: 'info',
      // Cell: ({ row }: any) => <DataTableInfoCell row={row.original} actions={row.values.actions} />,
    },
    {
      Header: 'actions',
      accessor: 'actions',
      Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
      alignCell: 'right',
      isSortable: false,
      width: '5%',
    },
  ],
};

function Assets() {
  const { assets } = useFirebaseAssetAPI();
  const { showAssetForm, showAssetDeleteDialog } = useBaseForm();

  const [isGridView, setViewType] = useState(false);

  const onHistory = (events: any) => console.log('handleHistory for', events);

  const mapAssets =
    (assets &&
      assets?.map((asset: any) => {
        if (!asset) {
          return null;
        }

        return {
          ...asset,
          actions: {
            history: () => onHistory(asset.events),
            edit: (values: any = asset, tabs: any = {}) =>
              showAssetForm({ ...values, initialTabs: tabs }),
            delete: () => showAssetDeleteDialog(asset),
          },
        };
      })) ||
    [];

  const handleChange = () => {
    setViewType(!isGridView);
  };

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={assetTable.columns}
        data={mapAssets}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <Box sx={{ background: '#DBE1E6', borderRadius: '12px' }}>
              <IconButton onClick={handleChange}>
                <Icon
                  fontSize="small"
                  color="inherit"
                  sx={!isGridView ? { background: 'white', borderRadius: '6px' } : {}}
                >
                  list
                </Icon>
              </IconButton>
              <IconButton onClick={handleChange}>
                <Icon
                  fontSize="small"
                  color="inherit"
                  sx={isGridView ? { background: 'white', borderRadius: '6px' } : {}}
                >
                  grid_view
                </Icon>
              </IconButton>
            </Box>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <IconButton onClick={() => showAssetForm()}>
              <Icon fontSize="small" color="inherit">
                add
              </Icon>
            </IconButton>
          </>
        }
      />
    ),
    [assets]
  );

  const generateCards = useDeepCompareMemo(
    () => (
      <AssetsCard
        data-testid="assetsCard"
        data={mapAssets}
        isGridView={isGridView}
        handleChange={handleChange}
        showAssetForm={showAssetForm}
      />
    ),
    [assets, isGridView]
  );

  return (
    <DashboardLayout>
      {isGridView ? <>{generateCards}</> : <Card>{generateTable}</Card>}
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(Assets);

import { Employee } from 'constants/Employee';
import { RoleType } from 'constants/Roles';
import { formatFiles } from 'constants/Util';

import { EmployeeDocumentType } from '../schemas/employeeConfig';

export const employeeDataVersion: number = 2;

const convertRolesV1 = (roles: any) =>
  roles.reduce((acc: any, curr: any) => ({ ...acc, [curr]: 3 }), {});

const formatEmployeeV1 = (data: any): Employee => ({
  id: data?.id || '',
  firstName: data?.name?.split(' ')[0] || '',
  lastName: data?.name?.split(' ').pop() || '',
  roles:
    (data?.roles &&
      convertRolesV1(
        data.roles.map((role: any) => (role === 'ACCOUNTING' ? RoleType.ACCOUNTANT : role))
      )) ||
    {},
  contact: {
    email: data?.email || '',
    phone: data?.phone || '',
    extension: '',
  },
  address: {
    address1: data?.address1 || '',
    address2: data?.address2 || '',
    city: data?.city || '',
    state: data?.state || '',
    zip: data?.zip || '',
  },
  status: data?.status || null,
  version: employeeDataVersion,
  files: formatFiles(EmployeeDocumentType, data?.files || {}),
  groups:
    data?.groups?.map((group: any) => ({
      id: group.id,
    })) || null,
  unit: data?.unit || null,
  code: data?.code || null,
  banking: data?.banking || null,
});

const formatEmployeeV2 = (data: any): Employee => ({
  id: data.id || '',
  firstName: data.firstName || '',
  lastName: data.lastName || '',
  roles: data.roles || {},
  contact: data.contact || {},
  address: data.address || {},
  status: data.status || '',
  version: employeeDataVersion,
  files: formatFiles(EmployeeDocumentType, data?.files),
  groups:
    data?.groups?.map((group: any) => ({
      id: group.id,
    })) || null,
  unit: data?.unit || null,
  code: data?.code || null,
  banking: data?.banking || null,
});

const formatEmployeeData = (data: any): Employee => {
  const version = data?.version || employeeDataVersion;

  switch (version) {
    case 1: {
      return formatEmployeeV1(data);
    }

    case 2: {
      return formatEmployeeV2(data);
    }

    default: {
      return formatEmployeeV1(data);
    }
  }
};

export const formatEmployeesData = (data: any): Employee[] =>
  Object.values(data).map((value) => formatEmployeeData(value));

export default formatEmployeeData;

/* eslint-disable no-unused-vars */
import { RoleType } from 'constants/Roles';
import { Document } from 'constants/Document';

import { Group } from './Group';
import { Employee } from './Employee';
import { Load } from './Load';

export enum UnitStatus {
  RESET = 'RESET',
  OFF = 'OFF',
  SCALE = 'SCALE',
  INSPECTION = 'INSPECTION',
  ACCIDENT = 'ACCIDENT',
  FUEL = 'FUEL',
  MAINTENANCE = 'MAINTENANCE',
  EXHANGE = 'EXHANGE',
  BREAKDOWN = 'BREAKDOWN',
  STOP = 'STOP',
  WAITING = 'WAITING',
  DELAY = 'DELAY',
  READY = 'READY',
}

enum UnitEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

export interface UnitEvent {
  timestamp: number;
  status: UnitStatus;
  note?: string;
  user?: string;
  action: UnitEventAction;
  location?: any;
  target?: string;
  source?: string;
}

interface Unit {
  id: string;
  status: UnitStatus;
  drivers: Employee[];
  truck: any;
  trailer?: any;
  loads: Load[];
  version: number;
  events?: UnitEvent[];
}

export { UnitEventAction };
export type { Unit };

/* eslint-disable no-unused-vars */
import { RoleType } from 'constants/Roles';
import { Document } from 'constants/Document';

interface GroupContact {
  phone: string;
  extension: string;
  email: string;
  fax: string;
}

interface GroupAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

enum GroupStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  HIDDEN = 'HIDDEN',
}

enum GroupType {
  INTERNAL = 'Internal',
  CONTRACTOR = 'Contractor',
  BROKER = 'Broker',
  LOCATION = 'Location',
  INSURANCE = 'Insurance',
  FACTORING = 'Factoring',
  LENDER = 'Lender',
  REPAIRSHOP = 'Repairshop',
  OTHER = 'Other',
}

enum GroupEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

interface GroupEvent {
  timestamp: number;
  status: GroupStatus;
  note?: string;
  user?: string;
  action: GroupEventAction;
}

interface Group {
  id: string;
  type: GroupType;
  companyName: string;
  roles: RoleType[];
  contact: GroupContact;
  status: GroupStatus;
  address: GroupAddress;
  version: number;
  events?: GroupEvent[];
  members?: any;
  assets?: any;
  banking?: any;
  authority?: any;
  files: { [key: string]: { [key: string]: Document } };
  code?: any;
}

export { GroupStatus, GroupEventAction, GroupType };
export type { Group };

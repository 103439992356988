/* eslint-disable react/destructuring-assignment */
import { FormConfig, FormField } from 'constants/form/Form';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Grid, Tab } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

import BaseForm from './BaseTab';

interface VersionTabsProps {
  versionConfig: FormConfig;
  formValues: { values: any; initialTabs: any };
  validation: { touched: any; errors: any };
  actions: {
    setFieldValue: any;
    tabActions: { setSelectedTypeTab: any; setSelectedVersionTab: any; handleInitialTab: any };
  };
}

function VersionTabs({ versionConfig, formValues, validation, actions }: VersionTabsProps) {
  const versionTabs = Object.keys(versionConfig).reverse();
  const [versionTab, setVersionTab] = useState<string>(
    formValues?.initialTabs?.version || versionTabs[0]
  );

  const {
    tabActions: { setSelectedVersionTab, handleInitialTab },
  } = actions;

  useEffect(() => {
    setSelectedVersionTab(versionTab);

    if (versionConfig[versionTab]) {
      handleInitialTab(versionConfig[versionTab].title, versionTab);
    }
  }, []);

  useEffect(() => {
    if (!versionTabs.includes(versionTab)) {
      setSelectedVersionTab(versionTabs[0]);
      setVersionTab(versionTabs[0]);
    }
  }, [versionTabs.length]);

  const onVersionTabChange = (_event: SyntheticEvent, newValue: string) => {
    setSelectedVersionTab(newValue);
    setVersionTab(newValue);
  };

  const renderVersionTab = (sideTabConfig: FormConfig | FormField[][] | FormField[][][]) =>
    Array.isArray(sideTabConfig) ? (
      <BaseForm
        config={sideTabConfig}
        formValues={formValues}
        validation={validation}
        actions={actions}
      />
    ) : null;

  return (
    <Grid container columnSpacing={2} direction="column" sx={{ mt: 0, p: 0 }}>
      <TabContext value={versionTab}>
        <Grid item xs={12} sm={3} sx={{ mt: 0, p: 0 }}>
          <TabList
            onChange={onVersionTabChange}
            orientation="horizontal"
            variant="scrollable"
            allowScrollButtonsMobile
          >
            {versionTabs.map((versionKey) => (
              <Tab label={versionConfig[versionKey].label} disableRipple value={versionKey} />
            ))}
          </TabList>
        </Grid>
        <Grid item xs={12} sm={9} sx={{ mt: 0, p: 0 }}>
          {versionTabs.map((versionKey) => (
            <TabPanel value={versionKey} sx={{ mt: 0, p: 0, pt: 1 }}>
              {renderVersionTab(versionConfig[versionKey].content)}
            </TabPanel>
          ))}
        </Grid>
      </TabContext>
    </Grid>
  );
}

export default VersionTabs;

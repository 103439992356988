import { Load } from 'constants/Load';

import { UnitEvent } from 'constants/Unit';

import React, { useCallback, useState } from 'react';

import {
  Autocomplete,
  Card,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import UpdateTimeline from 'components/CustomComponents/UpdateTimeline';
import { UpdateStop, UpdateStopColor, UpdateStopColorList } from 'components/CustomComponents/UpdateTimeline/types';
import MDButton from 'components/common/MDButton';

export interface DispatchFormProps {
  loads: Load[];
  unitEvents: { [key: string]: UnitEvent };
}

/*
  events: {
    1694790346707: {
        timestamp: number,
        status: "DISPATCHED",
        user: "TInkG4Vy8nl2I0S27QCm",
        action: "Edited",
        location: {
          coordinates: {
            lat: 40.18435,
            lng: -74.70653,
          },
        },
        target: "7u3xBYhrQBXQzoU7MV9d",
    }
  }

  loads: [
    {
      id: "7u3xBYhrQBXQzoU7MV9d",
      status: LOADING,
      route: {
        stops: [
          {
              type: 'Pick,
              status: LOADING,
              location: {
                address: "1762 1st Ave.", 
                city: "New York",
                state: "NY" 
                zip: "10128", 
                coordinates: { 
                  lat: "40.7803092", 
                  lng: "-73.9466757",
                },
              }
              timestamp: "1695081600000",
              timeFrom: "62400",
              timeTill: "62400",
          },
          {
              type: 'Drop,
              status: SCHEDULED,
              location: {
                name: "239 Butler St", 
                address: "239 Butler St", 
                city: "Trenton"
                state: "NJ", 
                zip: "08611", 
                coordinates: { 
                  lat: "40.2138078", 
                  lng: "-74.74797819999999" 
                },
              }
              timestamp: "1695168000000",
              timeFrom: "22400",
              timeTill: "82400",
          }
        ],
      }
    },
    {
      id: "o3XhsZ9IUsm4YlsMgJKQ",
      status: DISPATCHED,
      route: {
        stops: [
          {
              type: 'Pick,
              status: SCHEDULED,
              location: {
                address: "", 
                city: "Wellington",
                state: "FL" 
                zip: "10128", 
                coordinates: { 
                  lat: "26.6617635", 
                  lng: "-80.2683571",
                },
              }
              timestamp: "1669680000000",
          },
          {
              type: 'Drop,
              status: SCHEDULED,
              location: {
                address: "45 Rockefeller Plaza", 
                city: "New York"
                state: "NY", 
                zip: "10111", 
                coordinates: { 
                  lat: "40.7587402", 
                  lng: "-73.9786736" 
                },
              }
              timestamp: "1670457600000",
          }
        ],
      }
    }
  ]
*/
export const DispatchForm: React.FC<DispatchFormProps> = ({ loads, unitEvents }) => {
  // Prefill with default values from {loads, unitEvents}
  const [value, setValue] = useState({
    status: '', //of LoadStatus | UnitStatus
    loadId: 1, //change to autocomplete of Load
    rate: '', //rate is number
    pickupLocation: '', // prefill from Load location
    unit: '', // prefill from Load
    unitLocation: '', // attempt to get the current location from GPS provider
    eta: '',
    dispatcher: '',
    broker: '',
    brokerContact: '',
  });

  /* 
    TODO generate UpdateStop based on Loads and UnitEvents
    Join Loads.route.stops: LoadRouteStop[] and UnitEvent[] 
    filtered from first LoadRouteStop status == DISPATCHED's timestamp found in Load.events: LoadEvent[]
    and UnitEvent status == STOP, FUEL, SCALE, ACCIDENT, INSPECTION, BREAKDOWN, MAINTENANCE, RESET, OFF, EXHANGE
    Map UpdateStop from the joined array of LoadRouteStop and UnitEvent
    Sort by timestamp
  */
  const generateUpdateStops = useCallback(
    (loads: Load[], unitEvents: { [key: string]: UnitEvent }): UpdateStop[] => {
      let updateStop: UpdateStop[] = [];

      loads.forEach ((load, indexLoad) => {
        if (load.route?.stops) {
          load.route.stops.forEach((stop, indexStop) => {
            updateStop = [ 
              ...updateStop,
              {
                status: stop?.event?.status, //stop status
                timestamp: stop?.event?.timestamp || 0, // status change timestamp
                type: stop.type, // stop type PICK, DROP, STOP, FUEL, SCALE, ACCIDENT, INSPECTION, BREAKDOWN, MAINTENANCE, RESET, OFF, EXHANGE
                distance: 28,
                appointment: '8:00 am, 9/16/23', // stop?.timeFrom && stop?.timeTill ? "stop.timestamp + stop.timeFrom} - {stop.timestamp + stop.timeTill" : "stop.timestamp"
                address: `${stop.location.city} ${stop.location.state} ${stop.location.zip}`,
                color: UpdateStopColorList[indexLoad],
                isActive: true,
                isLastStop: indexStop === load.route!.stops.length - 1,
                isFirstStop: indexStop === 0,
              },
            ];
          })
        }
      })

      const unitStops: UpdateStop[] = Object.keys(unitEvents).map((key) => {
        const event = unitEvents[key];

        return {
          status: event.status,
          timestamp: event.timestamp || 0,
          type: event.status,
          distance: 28,
          appointment: '8:00 am, 9/16/23',
          address: `${event.location.city} ${event.location.state} ${event.location.zip}`,
          color: UpdateStopColor.GREY,
          isActive: true,
          }
      })

      // TODO review the filtering logic later
      // Idea is to get rid of all unit events that happened before first load except for the Dispatch event for the first load :)
      // const startTimestamp = updateStop.sort((a, b) => (a.timestamp - b.timestamp))[0].timestamp;
      // unitStops.filter((stop) => (startTimestamp > stop.timestamp))

      updateStop = [...updateStop, ...unitStops];

      return updateStop.sort((a, b) => (a.timestamp - b.timestamp));
    },
    [loads, unitEvents]
  );

  return (
    <Card sx={{ width: 'fit-content', padding: '35px', paddingY: '40px' }}>
      <Stack direction="column" gap={4}>
        <Grid container xs={12} justifyContent="space-between" direction="row">
          <Typography variant="h3">Dispatch Form</Typography>
          <Grid>
            {/* Simple/Extended Switch */}
            {/* Drag icon */}
          </Grid>
        </Grid>
        <Stack direction="row" gap={5}>
          <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <InputLabel>Status</InputLabel>
            <Select
              sx={{ padding: '12px' }}
              value={value.status}
              label="Age"
              onChange={(event) => setValue({ ...value, status: event.target.value })}
            >
              <MenuItem value="DISPATCHED">DISPATCHED</MenuItem>
              <MenuItem value="ENROUTE">ENROUTE</MenuItem>
              <MenuItem value="ARRIVED">ARRIVED</MenuItem>
            </Select>
            <TextField
              type="number"
              label="Load Id"
              onChange={(event) => setValue({ ...value, loadId: Number(event.target.value) })}
            />
            <TextField
              label="Rate"
              onChange={(event) => setValue({ ...value, rate: event.target.value })}
            />
            <Autocomplete
              disablePortal
              options={[{ label: 'Test', value: 'test' }]}
              onChange={(event, newValue) =>
                setValue({ ...value, pickupLocation: newValue ? newValue.value : '' })
              }
              renderInput={(params) => <TextField {...params} label="Pickup Location" />}
            />
            <Autocomplete
              disablePortal
              options={[
                { label: 'Test', value: 'test' },
                { label: 'Test', value: 'test' },
              ]}
              onChange={(event, newValue) =>
                setValue({ ...value, unit: newValue ? newValue.value : '' })
              }
              renderInput={(params) => <TextField {...params} label="Unit" />}
            />
            <Autocomplete
              disablePortal
              options={[
                { label: 'Test', value: 'test' },
                { label: 'Test', value: 'test' },
              ]}
              onChange={(event, newValue) =>
                setValue({ ...value, unitLocation: newValue ? newValue.value : '' })
              }
              renderInput={(params) => <TextField {...params} label="Unit Location" />}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label="ETA" />
              </DemoContainer>
            </LocalizationProvider>
            <Autocomplete
              disablePortal
              options={[
                { label: 'Test', value: 'test' },
                { label: 'Test', value: 'test' },
              ]}
              renderInput={(params) => <TextField {...params} label="Dispatcher" />}
            />
            <Autocomplete
              disablePortal
              options={[
                { label: 'Test', value: 'test' },
                { label: 'Test', value: 'test' },
              ]}
              onChange={(event, newValue) =>
                setValue({ ...value, broker: newValue ? newValue.value : '' })
              }
              renderInput={(params) => <TextField {...params} label="Broker" />}
            />
            <Autocomplete
              disablePortal
              options={[
                { label: 'Test', value: 'test' },
                { label: 'Test', value: 'test' },
              ]}
              onChange={(event, newValue) =>
                setValue({ ...value, brokerContact: newValue ? newValue.value : '' })
              }
              renderInput={(params) => <TextField {...params} label="Broker Contact" />}
            />
          </FormControl>
          <Grid direction="column">
            {/* Tabs and Loading Type Select */}
            <UpdateTimeline stops={generateUpdateStops(loads, unitEvents)} />
            {/* Load Checks */}
          </Grid>
        </Stack>
        <Grid container xs={12} justifyContent="space-between" direction="row">
          <MDButton
            variant="gradient"
            color="light"
            sx={{ width: '120px', height: '50px', p: 0, borderRadius: '5px' }}
          >
            <Icon>close</Icon>&nbsp; Cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            sx={{ width: '120px', height: '50px', p: 0, borderRadius: '5px' }}
          >
            <Icon>check</Icon>&nbsp; Submit
          </MDButton>
        </Grid>
      </Stack>
    </Card>
  );
};

import { FileUploadResult } from 'constants/Firebase';

import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

const useFirebaseFileAPI = () => {
  const firebase = useFirebase();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const uploadFile = (file: any, companyId: string, updateProgress: any = null) =>
    new Promise<FileUploadResult>((resolve, reject) => {
      const metadata = {
        contentType: file.extension,
      };

      const uploadTask = firebase
        .storage()
        .ref()
        .child(`${companyId}/documents/${file.path + file.name}`)
        .put(file.content, metadata);

      uploadTask.on(
        (firebase.storage as any).STATE_CHANGED,
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // eslint-disable-next-line no-unused-expressions
          updateProgress === null && updateProgress(file.version, progress);

          switch (snapshot.state) {
            case (firebase.storage as any).TaskState.PAUSED:
              break;
            case (firebase.storage as any).TaskState.RUNNING:
              break;
            default:
          }
        },
        (error) => {
          reject(error.code);

          switch (error.code) {
            case 'storage/unauthorized':
              break;
            case 'storage/canceled':
              break;
            case 'storage/unknown':
              break;
            default:
          }
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve({
              url: downloadURL,
              key: file.type,
              version: file.version,
            });
          });
        }
      );
    });

  const uploadBatch = (files: any, id: string, company: any = defaultCompany) => {
    const promises: Promise<FileUploadResult>[] = [];

    if (files) {
      Object.keys(files).forEach((type) => {
        Object.keys(type).forEach((version) => {
          const file = files[type][version]?.file;

          if (file?.content) {
            promises.push(
              uploadFile(
                {
                  content: file.content,
                  extension: file.extension,
                  name: file.name,
                  path: `/${id}/${[type]}/`,
                  version,
                  type,
                },
                company.companyId
              )
            );
          }
        });
      });
    }

    return Promise.all(promises);
  };

  const removeFile = (url: string) => firebase.storage().refFromURL(url).delete();

  const removeBatch = (files: any) => {
    const promises: Promise<void>[] = [];

    if (files) {
      Object.keys(files).forEach((type) => {
        Object.keys(type).forEach((version) => {
          const file = files[type][version]?.file;

          if (file?.url) {
            promises.push(removeFile(file.url));
          }
        });
      });
    }

    return Promise.all(promises);
  };

  return {
    uploadFile,
    uploadBatch,
    removeFile,
    removeBatch,
  };
};

export default useFirebaseFileAPI;

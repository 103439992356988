import React, { FC, forwardRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  PaperProps,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import Draggable from 'react-draggable';
import { TransitionProps } from '@mui/material/transitions';

export interface AlertDialogProps {
  isOpen: boolean;
  title?: string;
  text?: string;
  actions?: {
    confirm: {
      text: string;
      action: () => Promise<void>;
    };
    cancel: {
      text: string;
      action: () => void;
    };
  };
  [key: string]: any;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const AlertDialog: FC<AlertDialogProps> = forwardRef(
  ({ id, isOpen = true, title, text, actions, ref }) => (
    <Dialog
      open={isOpen}
      onClose={actions?.cancel?.action}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      TransitionComponent={Transition}
      ref={ref}
    >
      <DialogTitle style={{ cursor: 'move' }} id={id}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions?.cancel?.action && (
          <Button autoFocus onClick={actions.cancel.action}>
            {actions?.cancel?.text}
          </Button>
        )}
        {actions?.confirm?.action && (
          <Button onClick={actions.confirm.action}>{actions.confirm?.text}</Button>
        )}
      </DialogActions>
    </Dialog>
  )
);

export default AlertDialog;

/* eslint-disable no-unused-vars */
import { RoleType } from 'constants/Roles';
import { Document } from 'constants/Document';

import { Group } from './Group';
import { Employee } from './Employee';

interface LoadContact {
  phone: string;
  extension: string;
  email: string;
  fax: string;
}

interface LoadAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export enum LoadStatus {
  MATCHED = 'MATCHED',
  OFFERED = 'OFFERED',
  INQUIRED = 'INQUIRED',
  QUOTED = 'QUOTED',
  BOOKED = 'BOOKED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  SCHEDULED = 'SCHEDULED',
  DISPATCHED = 'DISPATCHED',
  ENROUTE = 'ENROUTE',
  ARRIVED = 'ARRIVED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  UNLOADING = 'UNLOADING',
  UNLOADED = 'UNLOADED',
  DELIVERED = 'DELIVERED',
}

enum LoadType {
  PO = 'Power Only',
  DryVan = 'Dry Van',
  Reefer = 'Reefer',
  Flatbedd = 'Flatbed',
  OTHER = 'Other',
}

export enum LoadSize {
  Full = 'Full',
  LTL = 'LTL',
}

export enum LoadPattern {
  Straight = 'Straight',
  Turned = 'Turned',
  Pinwheeled = 'Pinwheeled',
  DoubleStraight = 'Double Straight',
  DoubleTurned = 'Double Turned',
  DoublePinwheeled = 'Double Pinwheeled',
}

enum LoadEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

interface LoadEvent {
  timestamp: number;
  status: LoadStatus;
  note?: string;
  user?: string;
  action: LoadEventAction;
}

interface LoadInfo {
  id: string;
  size: LoadSize | null;
  milesLoaded: string;
  type: LoadType | null;
}

interface LoadAccounting {
  rate: string;
}

export enum LoadRouteType {
  Pick = 'Pick',
  Drop = 'Drop',
}

export interface LoadRouteStop {
  type: LoadRouteType;
  event?: LoadEvent;
  location: any;
  timestamp: number | null;
  timeFrom?: number | null;
  timeTill?: number | null;
}

export interface LoadRoute {
  stops: LoadRouteStop[];
  directions?: any;
}

interface Load {
  id: string;
  info: LoadInfo;
  accounting: LoadAccounting;
  status: LoadStatus;
  bookedBy?: any;
  assignedTo?: any;
  broker?: any;
  route: LoadRoute | null;
  version: number;
  events?: LoadEvent[];
  files: { [key: string]: { [key: string]: Document } };
  initialValues?: any;
}

export { LoadEventAction, LoadType };
export type { Load };

import { useFirestore } from 'react-redux-firebase';

import { CodeAPI } from '../CodeAPI';

const useFirebaseCodeAPI = (): CodeAPI => {
  const firestore = useFirestore();

  const updateCode = (data: any) =>
    firestore.doc(`codes/${data.code}`).update({
      roles: data.roles,
    });

  const addCode = (data: any) => firestore.collection('codes').add(data);

  const deleteCode = (codeId: string) => firestore.doc(`codes/${codeId}`).delete();

  return {
    addCode,
    updateCode,
    deleteCode,
  };
};

export default useFirebaseCodeAPI;

/* eslint-disable no-unused-vars */
import { RoleType } from 'constants/Roles';
import { Document } from 'constants/Document';

interface EmployeeContact {
  phone: string;
  extension: string;
  email: string;
}

interface EmployeeAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

enum EmployeeStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  HIDDEN = 'HIDDEN',
}

enum EmployeeEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

interface EmployeeEvent {
  timestamp: number;
  status: EmployeeStatus;
  note?: string;
  user?: string;
  action: EmployeeEventAction;
}

interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  roles: RoleType[];
  contact: EmployeeContact;
  status: EmployeeStatus;
  address: EmployeeAddress;
  version: number;
  events?: EmployeeEvent[];
  files: { [key: string]: { [key: string]: Document } };
  code: any;
  groups?: any;
  banking?: any;
  unit: any;
}

export { EmployeeStatus, EmployeeEventAction };
export type { Employee };

import { FileUploadResult } from 'constants/Firebase';
import { Load, LoadEventAction, LoadStatus } from 'constants/Load';
import { intersectFilesUpload } from 'constants/Util';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { formatLoadData } from 'screens/freight/loads/data/migration';
import { formatLoadsValuesList } from 'screens/freight/loads/schemas/formatLoadValues';

import { LoadAPI } from '../LoadAPI';

import updatedDependentFields from './updatedDependentFields';
import useFirebaseFileAPI from './firebaseFileAPI';

const useFirebaseLoadAPI = (): LoadAPI => {
  const firestore = useFirestore();
  const firebaseFileAPI = useFirebaseFileAPI();

  const loads: Load[] = useSelector((state: any) => {
    const data = state?.firestore?.data;
    let result: Load[] = [];

    if (data) {
      const { loads, employees, units, groups, assets } = data;
      result = formatLoadsValuesList(loads, employees, units, groups, assets);
    }

    return result;
  });

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const defualtLoadAddEvent = {
    timestamp: Date.now(),
    status: LoadStatus.BOOKED,
    user: defaultCompany?.employeeId,
    action: LoadEventAction.ADD,
  };

  const add = (path: string, data: any) => firestore.collection(path).add(formatLoadData(data));

  const update = (path: string, data: any) => firestore.doc(path).update(formatLoadData(data));

  const remove = (id: string) => firestore.doc(id).delete();

  const addLoad = (
    data: any = {},
    company: any = defaultCompany,
    event: any = defualtLoadAddEvent
  ) =>
    add(`company/${company.companyId}/loads`, {
      ...data,
      events: {
        [`${Date.now()}`]: event,
      },
    });

  const updateLoad = (
    loadId: string,
    data: any,
    company: any = defaultCompany,
    event: any = defualtLoadAddEvent
  ) =>
    update(`company/${company.companyId}/loads/${loadId}`, {
      ...data,
      events: {
        ...data.events,
        [`${Date.now()}`]: event,
      },
    });

  const addLoadEvent = (event: any, companyId: string = defaultCompany.companyId) =>
    add(`company/${companyId}/loadsEvents`, event);

  const removeLoad = (id: string, companyId: string) => remove(`company/${companyId}/loads/${id}`);

  const onRemoveLoad = ({ files, id, ...rest }: Load, company: any = defaultCompany) =>
    firebaseFileAPI.removeBatch(files).then(() =>
      removeLoad(id, company.companyId).then(() =>
        addLoadEvent({
          [`${Date.now()}`]: {
            timestamp: Date.now(),
            user: company.employeeId,
            action: LoadEventAction.REMOVE,
            target: id,
          },
        }).then(() => updatedDependentFields({ id, ...rest }, {}, firestore, defaultCompany))
      )
    );

  const onEditLoad = (
    { files, ...rest }: Load,
    initialValues: Load | undefined = undefined,
    company: any = defaultCompany
  ) =>
    firebaseFileAPI.uploadBatch(files, rest.id).then((promiseResults: FileUploadResult[]) => {
      const updatedFiles = intersectFilesUpload(promiseResults, files);
      const updatedData: any = { ...rest };

      if (updatedFiles) {
        updatedData.files = updatedFiles;
      }

      return updateLoad(
        rest.id,
        {
          ...rest,
          files: intersectFilesUpload(promiseResults, files),
        },
        company
      ).then(() => updatedDependentFields(initialValues, rest, firestore, defaultCompany));
    });

  const onAddLoad = (
    { files, ...rest }: Load,
    initialValues: Load | undefined = undefined,
    company: any = defaultCompany
  ) =>
    addLoad().then((createResult: any) => {
      firebaseFileAPI
        .uploadBatch(files, createResult.id)
        .then((promiseResults: FileUploadResult[]) =>
          updateLoad(
            createResult.id,
            {
              ...rest,
              files: intersectFilesUpload(promiseResults, files),
              id: createResult.id,
            },
            company
          ).then(() =>
            addLoadEvent({
              [`${Date.now()}`]: {
                timestamp: Date.now(),
                user: company.employeeId,
                action: LoadEventAction.ADD,
                target: createResult.id,
              },
            }).then(() => updatedDependentFields(initialValues, rest, firestore, defaultCompany))
          )
        );
    });

  return {
    onAddLoad,
    onEditLoad,
    onRemoveLoad,
    addLoadEvent,
    loads,
  };
};

export default useFirebaseLoadAPI;

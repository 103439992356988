import React  from 'react';
import styled from 'styled-components';

interface TabProps {
  tabs: string[];
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const TabWrapper = styled.div`
  display: grid;
`;

interface TabButtonProps {
  active: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  background-color: ${(props) => (props.active ? '#ffffff' : '#ffffff')};
  color: ${(props) => (props.active ? '#344767' : '#666666')};
  padding: 8px 5px;
  border: none;
  cursor: pointer;
  border-left: ${(props) => (props.active ? '4px solid #344767' : '4px solid #f5f5f5')};
  font-size: 14px;
`;

const NewTab: React.FC<TabProps> = ({ tabs, activeTab, onTabChange }) => {
  const handleTabClick = (tab: string) => {
    onTabChange(tab);
  };

  return (
    <TabWrapper>
      {tabs.map((tab) => (
        <TabButton key={tab} active={tab === activeTab} onClick={() => handleTabClick(tab)}>
          {tab}
        </TabButton>
      ))}
    </TabWrapper>
  );
};

export default NewTab;

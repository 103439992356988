import { LoadStatus } from './Load';
import { UnitStatus } from './Unit';

/* eslint-disable no-unused-vars */
export enum UpdateType {
  DISPATCH,
  UNIT,
  ACCOUNTING,
  STOP,
  UPDATE,
  FLEET,
  SAFETY
}

export enum AccountingUpdateStatus {
  DOC_MISSING = 'DOC MISSING',
  DOC_REQUESTED = 'DOC REQUESTED',
  DOC_SUBMITTED = 'DOC SUBMITTED',
  DOC_READY = 'DOC_READY',
  INVOICED = 'INVOICED',
  FACTORED = 'FACTORED',
  DENIED = 'DENIED',
  AGED_30 = 'AGED_30',
  AGED_60 = 'AGED_60',
  AGED_90 = 'AGED_90',
  AGED_120 = 'AGED_120',
  PAID = 'PAID',
  COLLECTION = 'COLLECTION',
  LOSS = 'LOSS',
  FUNDED = 'FUNDED',
  UNDERPAID = 'UNDERPAID',
}

export interface UpdateStatusNode {
  next: UpdateStatusNode[] | null;
  label: string;
  type: LoadStatus | UnitStatus | AccountingUpdateStatus;
  role: UpdateType[];
  source?: any;
  target?: any;
  location?: any;
}

let EnrouteStatus: any = {};
let ArrivedStatus: any = {};
let WaitingStatus: any = {};

const CancelledStatus: UpdateStatusNode = {
  next: null,
  label: 'Cancelled',
  type: LoadStatus.CANCELLED,
  role: [UpdateType.DISPATCH, UpdateType.UPDATE],
};

const RejectedStatus: UpdateStatusNode = {
  next: null,
  label: 'Rejected',
  type: LoadStatus.REJECTED,
  role: [UpdateType.DISPATCH],
};

const DelayStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus],
  label: 'Delay',
  type: UnitStatus.DELAY,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const StopStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus],
  label: 'Stop',
  type: UnitStatus.STOP,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP],
};

WaitingStatus = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, StopStatus],
  label: 'Waiting',
  type: UnitStatus.WAITING,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP],
};

const ResetStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus],
  label: 'Reset',
  type: UnitStatus.RESET,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP, UpdateType.SAFETY],
};

const OffStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus],
  label: 'OFF',
  type: UnitStatus.OFF,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP, UpdateType.SAFETY],
};

const ScaleStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus],
  label: 'Scale',
  type: UnitStatus.SCALE,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP, UpdateType.ACCOUNTING],
};

const InspectionStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus, ResetStatus],
  label: 'Inspection',
  type: UnitStatus.INSPECTION,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP, UpdateType.SAFETY],
};

const AccidentStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus],
  label: 'Accident',
  type: UnitStatus.ACCIDENT,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP, UpdateType.SAFETY],
};

const FuelStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus, OffStatus, ResetStatus],
  label: 'Fuel',
  type: UnitStatus.FUEL,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP, UpdateType.ACCOUNTING],
};

const MaintananceStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus, OffStatus, ResetStatus],
  label: 'Maintanance',
  type: UnitStatus.MAINTENANCE,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP, UpdateType.ACCOUNTING, UpdateType.FLEET],
};

const ExchangeStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus, OffStatus, ResetStatus],
  label: 'Exchange',
  type: UnitStatus.EXHANGE,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP],
};

const BreakdownStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, WaitingStatus, OffStatus, ResetStatus],
  label: 'Breakdown',
  type: UnitStatus.BREAKDOWN,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP, UpdateType.ACCOUNTING, UpdateType.FLEET],
};

const PaidStatus: UpdateStatusNode = {
  next: null,
  label: 'Paid',
  type: AccountingUpdateStatus.PAID,
  role: [UpdateType.ACCOUNTING],
};

const LossStatus: UpdateStatusNode = {
  next: null,
  label: 'Loss',
  type: AccountingUpdateStatus.LOSS,
  role: [UpdateType.ACCOUNTING],
};

const CollectionStatus: UpdateStatusNode = {
  next: [PaidStatus, LossStatus],
  label: 'Collection',
  type: AccountingUpdateStatus.COLLECTION,
  role: [UpdateType.ACCOUNTING],
};

const Aged120Status: UpdateStatusNode = {
  next: [PaidStatus, LossStatus],
  label: 'Aged 120 days',
  type: AccountingUpdateStatus.AGED_120,
  role: [UpdateType.ACCOUNTING],
};

const Aged90Status: UpdateStatusNode = {
  next: [PaidStatus, LossStatus, Aged120Status, CollectionStatus],
  label: 'Aged 90 days',
  type: AccountingUpdateStatus.AGED_90,
  role: [UpdateType.ACCOUNTING],
};

const Aged60Status: UpdateStatusNode = {
  next: [PaidStatus, LossStatus, Aged90Status, CollectionStatus],
  label: 'Aged 60 days',
  type: AccountingUpdateStatus.AGED_60,
  role: [UpdateType.ACCOUNTING],
};

const Aged30Status: UpdateStatusNode = {
  next: [PaidStatus, LossStatus, Aged60Status, CollectionStatus],
  label: 'Aged 30 days',
  type: AccountingUpdateStatus.AGED_30,
  role: [UpdateType.ACCOUNTING],
};

const InvoicedStatus: UpdateStatusNode = {
  next: [PaidStatus, LossStatus, Aged30Status, CollectionStatus],
  label: 'Invoiced',
  type: AccountingUpdateStatus.INVOICED,
  role: [UpdateType.ACCOUNTING],
};

const UnderpaidStatus: UpdateStatusNode = {
  next: [CollectionStatus, InvoicedStatus, PaidStatus],
  label: 'Underpaid',
  type: AccountingUpdateStatus.UNDERPAID,
  role: [UpdateType.ACCOUNTING],
};

const FundedStatus: UpdateStatusNode = {
  next: [PaidStatus, LossStatus, Aged30Status, CollectionStatus],
  label: 'Funded',
  type: AccountingUpdateStatus.FUNDED,
  role: [UpdateType.ACCOUNTING],
};

const DeniedStatus: UpdateStatusNode = {
  next: [InvoicedStatus, CollectionStatus],
  label: 'Denied',
  type: AccountingUpdateStatus.DENIED,
  role: [UpdateType.ACCOUNTING],
};

const FactoredStatus: UpdateStatusNode = {
  next: [FundedStatus, DeniedStatus],
  label: 'Factored',
  type: AccountingUpdateStatus.FACTORED,
  role: [UpdateType.ACCOUNTING],
};

const DocReadyStatus: UpdateStatusNode = {
  next: [FactoredStatus, InvoicedStatus],
  label: 'Documents Ready',
  type: AccountingUpdateStatus.DOC_READY,
  role: [UpdateType.ACCOUNTING],
};

let DocMissingStatus: any = {};

const DocSubmittedStatus: UpdateStatusNode = {
  next: [DocReadyStatus, DocMissingStatus],
  label: 'Documents Submitted',
  type: AccountingUpdateStatus.DOC_SUBMITTED,
  role: [UpdateType.ACCOUNTING],
};

const DocRequestedStatus: UpdateStatusNode = {
  next: [DocSubmittedStatus],
  label: 'Documents Requested',
  type: AccountingUpdateStatus.DOC_REQUESTED,
  role: [UpdateType.ACCOUNTING],
};

DocMissingStatus = {
  next: [DocSubmittedStatus, DocRequestedStatus],
  label: 'Documents Missing',
  type: AccountingUpdateStatus.DOC_MISSING,
  role: [UpdateType.ACCOUNTING],
};

const DeliveredStatus: UpdateStatusNode = {
  next: [DocMissingStatus, DocReadyStatus],
  label: 'Delivered',
  type: LoadStatus.DELIVERED,
  role: [UpdateType.DISPATCH, UpdateType.UPDATE, UpdateType.ACCOUNTING],
};

EnrouteStatus = {
  next: [CancelledStatus, RejectedStatus, ArrivedStatus, StopStatus, DelayStatus],
  label: 'Enroute',
  type: LoadStatus.ENROUTE,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const UnloadedStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus, DeliveredStatus],
  label: 'Unloaded',
  type: LoadStatus.UNLOADED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const UnloadingStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, UnloadedStatus],
  label: 'Unloading',
  type: LoadStatus.UNLOADING,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const LoadedStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus],
  label: 'Loaded',
  type: LoadStatus.LOADED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const LoadingStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, LoadedStatus],
  label: 'Loading',
  type: LoadStatus.LOADING,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

ArrivedStatus = {
  next: [CancelledStatus, RejectedStatus, LoadingStatus, UnloadingStatus],
  label: 'Arrived',
  type: LoadStatus.ARRIVED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const DispatchedStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, EnrouteStatus], // ADD EXCHNAGED
  label: 'Dispatched',
  type: LoadStatus.DISPATCHED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const BookedStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, DispatchedStatus],
  label: 'Booked',
  type: LoadStatus.BOOKED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT],
};

const QuotedStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, BookedStatus],
  label: 'Quoted',
  type: LoadStatus.QUOTED,
  role: [UpdateType.DISPATCH],
};

const InquiredStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, QuotedStatus, BookedStatus],
  label: 'Inquired',
  type: LoadStatus.INQUIRED,
  role: [UpdateType.DISPATCH],
};

const OfferedStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, InquiredStatus, QuotedStatus, BookedStatus],
  label: 'Offered',
  type: LoadStatus.OFFERED,
  role: [UpdateType.DISPATCH],
};

const MatchedStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, InquiredStatus, QuotedStatus, BookedStatus],
  label: 'Matched',
  type: LoadStatus.MATCHED,
  role: [UpdateType.DISPATCH],
};

const ReadyStatus: UpdateStatusNode = {
  next: [CancelledStatus, RejectedStatus, InquiredStatus, QuotedStatus, BookedStatus],
  label: 'Matched',
  type: LoadStatus.MATCHED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

export const UpdateStatusList: { [key: string]: UpdateStatusNode } = {
  [LoadStatus.MATCHED]: MatchedStatus,
  [LoadStatus.OFFERED]: OfferedStatus,
  [LoadStatus.INQUIRED]: InquiredStatus,
  [LoadStatus.QUOTED]: QuotedStatus,
  [LoadStatus.BOOKED]: BookedStatus,
  [LoadStatus.REJECTED]: RejectedStatus,
  [LoadStatus.CANCELLED]: CancelledStatus,
  [LoadStatus.DISPATCHED]: DispatchedStatus,
  [LoadStatus.ENROUTE]: EnrouteStatus,
  [LoadStatus.ARRIVED]: ArrivedStatus,
  [LoadStatus.LOADING]: LoadingStatus,
  [LoadStatus.LOADED]: LoadedStatus,
  [LoadStatus.UNLOADING]: UnloadingStatus,
  [LoadStatus.UNLOADED]: UnloadedStatus,
  [UnitStatus.READY]: ReadyStatus,
  [UnitStatus.WAITING]: WaitingStatus,
  [UnitStatus.RESET]: ResetStatus,
  [UnitStatus.OFF]: OffStatus,
  [UnitStatus.SCALE]: ScaleStatus,
  [UnitStatus.INSPECTION]: InspectionStatus,
  [UnitStatus.ACCIDENT]: AccidentStatus,
  [UnitStatus.FUEL]: FuelStatus,
  [UnitStatus.MAINTENANCE]: MaintananceStatus,
  [UnitStatus.EXHANGE]: ExchangeStatus,
  [UnitStatus.BREAKDOWN]: BreakdownStatus,
  [UnitStatus.STOP]: StopStatus,
  [UnitStatus.DELAY]: DelayStatus,
  [LoadStatus.DELIVERED]: DelayStatus,
  [AccountingUpdateStatus.DOC_MISSING]: DocMissingStatus,
  [AccountingUpdateStatus.DOC_REQUESTED]: DocReadyStatus,
  [AccountingUpdateStatus.DOC_SUBMITTED]: DocSubmittedStatus,
  [AccountingUpdateStatus.DOC_READY]: DocReadyStatus,
  [AccountingUpdateStatus.INVOICED]: InvoicedStatus,
  [AccountingUpdateStatus.FACTORED]: FactoredStatus,
  [AccountingUpdateStatus.DENIED]: DeniedStatus,
  [AccountingUpdateStatus.AGED_30]: Aged30Status,
  [AccountingUpdateStatus.AGED_60]: Aged60Status,
  [AccountingUpdateStatus.AGED_90]: Aged90Status,
  [AccountingUpdateStatus.AGED_120]: Aged120Status,
  [AccountingUpdateStatus.PAID]: PaidStatus,
  [AccountingUpdateStatus.COLLECTION]: CollectionStatus,
  [AccountingUpdateStatus.LOSS]: LossStatus,
  [AccountingUpdateStatus.FUNDED]: FundedStatus,
  [AccountingUpdateStatus.UNDERPAID]: UnderpaidStatus,
};

/* eslint-disable no-unused-vars */
import { LoadRouteType } from 'constants/Load';

import { UnitStatus } from 'constants/Unit';

import { Stack, Typography } from '@mui/material';
import { ReactComponent as PickIcon } from 'assets/images/arrow_circle_up.svg';
import { ReactComponent as FuelIcon } from 'assets/images/fuel.svg';
import { ReactComponent as DropIcon } from 'assets/images/arrow_circle_down.svg';
import Time from 'assets/images/time.svg';
import { memo } from 'react';

import {
  StatusContainer,
  StyledContainer,
  StyledData,
  StyledInfo,
  StyledLine,
  StyledName,
  StyledStatusName,
} from './styled';
import { UpdateStop, UpdateStopColor, UpdateTimelineProps } from './types';

/** Example for calling Status component where needed
 * 
 * <Status
        ...UpdateStop[]
      />
 */

const UpdateTimeline: React.FC<UpdateTimelineProps> = ({ stops }) => {
  /* TODO
    Get icon based on type of the stop:
      PICK, DROP, STOP, FUEL, SCALE, ACCIDENT, INSPECTION, BREAKDOWN, MAINTENANCE, RESET, OFF, EXHANGE 
    and color: gray for empty truck or color coded by load: 1st is green, 2nd is blue, 3rd is orange, etc
  */
  const getStopIcon = (
    type: LoadRouteType | UnitStatus,
    color: UpdateStopColor,
    isActive: boolean //apple 20% opacity to the color if isActive == true
  ) => {
    switch (type) {
      case LoadRouteType.Pick:
        return <PickIcon fill={color} />;
      case UnitStatus.FUEL:
        return <FuelIcon fill={color} />;
      case LoadRouteType.Drop:
        return <DropIcon fill={color} />;
    }
  };

  const getRouteColor = (stops: UpdateStop[], index: number) => { 
    let color = 'transperant';

    if (stops[index].color === UpdateStopColor.GREY && !stops[index + 1].isFirstStop) {
      color = stops[index + 1].color;
    } else if (stops[index].color === stops[index + 1].color || !stops[index].isLastStop) {
      color = stops[index].color;
    } else if (!stops[index + 1].isFirstStop) { 
      color = stops[index + 1].color
    }

    return color !== UpdateStopColor.GREY ? color : 'transperant';
  }

  return (
    <StatusContainer>
      {stops.map((stop, index) => {
        return (
          <Stack
            key={index}
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            position="relative"
          >
            {stop.status === 'Delayed' && (
              <img width={18} height={18} src={Time} alt="status icon" />
            )}
            <StyledStatusName>
              <StyledName>{stop.status}</StyledName>
              <StyledData>{stop.timestamp}</StyledData>
            </StyledStatusName>
            <Stack>
              {getStopIcon(stop.type, stop.color, stop.isActive)}
              {index !== stops.length - 1 && (
                <StyledContainer>
                  <StyledLine
                    color={getRouteColor(stops, index)}
                  />
                  <Typography variant="caption">{stop.distance} miles</Typography>
                </StyledContainer>
              )}
            </Stack>
            <StyledInfo>
              <Stack direction="row">
                <Stack>
                  <Typography variant="body2">{stop.address}</Typography>
                  <StyledData>{stop.appointment}</StyledData>
                </Stack>
              </Stack>
            </StyledInfo>
          </Stack>
        );
      })}
    </StatusContainer>
  );
};

export default memo(UpdateTimeline);

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// formik components
import { Field } from 'formik';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/common/MDBox';
import MDTypography from 'components/common/MDTypography';
import MDInput from 'components/common/MDInput';
import  './FormField.css'

// Declaring props types for FormField
interface Props {
  label: string;
  name: string;
  [key: string]: any;
}

function FormField({ label, name, ...rest }: Props): JSX.Element {
  return (
    <MDBox mb={1.5}>
      <Field className="aaa" {...rest} name={name} as={MDInput} variant="standard" label={label} fullWidth />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          {/* <ErrorMessage className='error' name={name} /> */}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default FormField;

import { Asset, AssetEventAction, AssetStatus, AssetType } from 'constants/Asset';
import { FileUploadResult } from 'constants/Firebase';
import { intersectFilesUpload } from 'constants/Util';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import formatAssetData from 'screens/roster/assets/migration';
import { formatAssetsValuesList } from 'screens/roster/assets/formatAsset';

import { AssetAPI } from '../AssetAPI';

import useFirebaseFileAPI from './firebaseFileAPI';
import updatedDependentFields from './updatedDependentFields';

const useFirebaseAssetAPI = (): AssetAPI => {
  const firestore = useFirestore();
  const { uploadBatch } = useFirebaseFileAPI();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const assets: Asset[] = useSelector((state: any) => {
    const data = state?.firestore?.data;
    let result: Asset[] = [];

    if (data) {
      const { assets } = data;
      result = formatAssetsValuesList(assets);
    }

    return result;
  });

  const trucks = assets?.filter((asset: Asset) => asset.type === AssetType.TRUCK);
  const trailers = assets?.filter((asset: Asset) => asset.type === AssetType.TRAILER);

  const defualtAssetAddEvent = {
    timestamp: Date.now(),
    status: AssetStatus.PENDING,
    user: defaultCompany?.employeeId,
    action: AssetEventAction.ADD,
  };

  const add = (path: string, data: any) => firestore.collection(path).add(formatAssetData(data));

  const update = (path: string, data: any) => firestore.doc(path).update(formatAssetData(data));

  const remove = (id: string) => firestore.doc(id).delete();

  const addAsset = (
    data: any = {},
    company: any = defaultCompany,
    event: any = defualtAssetAddEvent
  ) =>
    add(`company/${company.companyId}/assets`, {
      ...data,
      events: {
        [`${Date.now()}`]: event,
      },
    });

  const updateAsset = (
    id: string,
    data: any,
    company: any = defaultCompany,
    event: any = defualtAssetAddEvent
  ) =>
    update(`company/${company.companyId}/assets/${id}`, {
      ...data,
      events: {
        ...data.events,
        [`${Date.now()}`]: event,
      },
    });

  const addAssetEvent = (event: any, companyId: string = defaultCompany.companyId) =>
    add(`company/${companyId}/assetsEvents`, event);

  const removeAsset = (id: string, companyId: string) =>
    remove(`company/${companyId}/assets/${id}`);

  const onRemoveAsset = (asset: Asset, company: any = defaultCompany) =>
    removeAsset(asset.id, company.companyId).then(() => {
      updatedDependentFields(asset, {}, firestore, defaultCompany);

      addAssetEvent({
        [`${Date.now()}`]: {
          timestamp: Date.now(),
          user: company.employeeId,
          action: AssetEventAction.REMOVE,
          target: asset.id,
        },
      });
    });

  const onEditAsset = (
    asset: Asset,
    initialValues: Asset | undefined = undefined,
    company: any = defaultCompany
  ) =>
    uploadBatch(asset.files, asset.id).then((promiseResults: FileUploadResult[]) =>
      updateAsset(asset.id, intersectFilesUpload(promiseResults, { ...asset }), company).then(() =>
        updatedDependentFields(initialValues, asset, firestore, defaultCompany)
      )
    );

  const onAddAsset = (
    asset: Asset,
    initialValues: Asset | undefined = undefined,
    company: any = defaultCompany
  ) =>
    addAsset({}, company).then((createResult: any) =>
      uploadBatch(asset.files, createResult.id).then((promiseResults: FileUploadResult[]) =>
        updateAsset(createResult.id, {
          ...intersectFilesUpload(promiseResults, { ...asset }),
          id: createResult.id,
        }).then(() =>
          addAssetEvent({
            [`${Date.now()}`]: {
              timestamp: Date.now(),
              user: company.employeeId,
              action: AssetEventAction.ADD,
              target: createResult.id,
            },
          }).then(() => updatedDependentFields(initialValues, asset, firestore, defaultCompany))
        )
      )
    );

  return {
    onAddAsset,
    onEditAsset,
    onRemoveAsset,
    assets,
    trucks,
    trailers,
  };
};

export default useFirebaseAssetAPI;

import BaseForm from 'components/BaseForm';
import AlertDialog from 'components/dialogs/AlertDialog';
import { useDispatch } from 'react-redux';
import { addForm, closeForm } from 'redux/slices/formsSlice';
import formatConnectionValues from 'screens/connections/integrations/schema/formatConnectionValues';
import { connectionFormSetup } from 'screens/connections/integrations/schema/connectionConfig';
import { unitFormSetup } from 'screens/dispatch/board/schemas/boardConfig';
import formatUnitValues from 'screens/dispatch/board/schemas/formatUnitValues';
import formatLoadValues from 'screens/freight/loads/schemas/formatLoadValues';
import { loadFormSetup } from 'screens/freight/loads/schemas/loadsConfig';
import { employeeFormSetup } from 'screens/roster/employees/schemas/employeeConfig';
import formatEmployeeValues from 'screens/roster/employees/schemas/formatEmployeeValues';
import formatGroupValues from 'screens/roster/groups/schemas/formatGroupValues';
import { groupFormSetup } from 'screens/roster/groups/schemas/groupsConfig';
import useFirebaseConnectionAPI from 'services/data/Firebase/firebaseConnectionAPI';
import useFirebaseEmpoyeeAPI from 'services/data/Firebase/firebaseEmpoyeeAPI';
import useFirebaseFileAPI from 'services/data/Firebase/firebaseFileAPI';
import useFirebaseGroupAPI from 'services/data/Firebase/firebaseGroupAPI';
import useFirebaseLoadAPI from 'services/data/Firebase/firebaseLoadAPI';
import useFirebaseUnitAPI from 'services/data/Firebase/firebaseUnitAPI';
import useFirebaseAssetAPI from 'services/data/Firebase/FirebaseAssetAPI';
import { assetFormSetup } from 'screens/roster/assets/configAsset';
import formatAssetValues from 'screens/roster/assets/formatAsset';
import { useSnackbar } from 'notistack';

const useBaseForm = () => {
  let showGroupForm: any = null;
  let showEmployeeForm: any = null;
  let showLoadForm: any = null;
  let showUnitForm: any = null;
  let showConnectionForm: any = null;
  let showAssetForm: any = null;

  const fileAPI = useFirebaseFileAPI();
  const { onEditEmployee, onAddEmployee, onRemoveEmployee, resetEmployeeCode } =
    useFirebaseEmpoyeeAPI();

  const { onAddGroup, onEditGroup, onRemoveGroup, resetGroupCode } = useFirebaseGroupAPI();
  const { onAddLoad, onEditLoad, onRemoveLoad } = useFirebaseLoadAPI();
  const { onEditUnit, onAddUnit, onRemoveUnit } = useFirebaseUnitAPI();
  const { onEditConnection, onAddConnection } = useFirebaseConnectionAPI();
  const { onEditAsset, onAddAsset, onRemoveAsset } = useFirebaseAssetAPI();

  const dispatcher = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const onClose = (id: number) => dispatcher(closeForm({ id }));

  const onShow = (generateForm: any) => {
    const id = Date.now();
    const form = generateForm(id);
    dispatcher(addForm({ id, form }));
  };

  const onDeleteFile = (downloadURL: string) => fileAPI.removeFile(downloadURL).then(() => {});

  const generateDialog = ({ id, title, text, actions }: any) => (
    <AlertDialog id={id} title={title} text={text} actions={actions} isOpen />
  );

  const generateForm = ({ dialog, setup, formValues, isAdding }: any) => (
    <BaseForm
      formValues={formValues}
      setup={setup}
      actions={{
        submit: (data: any) => {
          console.log('onSubmit', data);

          dialog
            .submit(data, formValues)
            .then(() => {
              enqueueSnackbar('Form submitted!', {
                preventDuplicate: true,
                variant: 'success',
              });

              onClose(dialog.id);
            })
            .catch((error: string) => {
              enqueueSnackbar(error, {
                preventDuplicate: true,
                variant: 'error',
              });

              onClose(dialog.id);
            });
        },
        upload: fileAPI.uploadFile,
        delete: onDeleteFile,
        close: (formId: number) => onClose(formId),
        // eslint-disable-next-line no-unused-vars
        dialog: (dialogConfig: (id: any) => { id: any; title: any; text: any; actions: any }) =>
          onShow((formId: any) => generateDialog(dialogConfig(formId))),
        showGroupForm,
        showEmployeeForm,
        showLoadForm,
        showUnitForm,
        showConnectionForm,
        showAssetForm,
      }}
      isDialogOpen
      isAdding={isAdding}
    />
  );

  showGroupForm = (formValues: { data: any; initialTabs: any } | undefined = undefined) =>
    onShow((formId: number) =>
      generateForm({
        dialog: {
          id: formId,
          submit: formValues ? onEditGroup : onAddGroup,
        },
        setup: groupFormSetup(formId, { members: 'employees', assets: 'assets' }),
        formValues: formValues || formatGroupValues({}),
        isAdding: !formValues,
      })
    );

  showEmployeeForm = (formValues: { data: any; initialTabs: any } | undefined = undefined) =>
    onShow((formId: any) =>
      generateForm({
        dialog: {
          id: formId,
          submit: formValues ? onEditEmployee : onAddEmployee,
        },
        setup: employeeFormSetup(formId, { groups: 'groups' }),
        formValues: formValues || formatEmployeeValues({}),
        isAdding: !formValues,
      })
    );

  const showEmployeeDeleteDialog = (employee: any) =>
    onShow((formId: any) =>
      generateDialog({
        id: formId,
        title: 'Delete',
        text: 'Do you want to delete the employee?',
        actions: {
          confirm: {
            text: 'Delete',
            action: () => onRemoveEmployee(employee).then(() => onClose(formId)),
          },
          cancel: {
            text: 'Cancel',
            action: () => onClose(formId),
          },
        },
      })
    );

  const showEmployeeCodeDialog = (employeeId: number, codeId: number) =>
    onShow((formId: number) =>
      generateDialog({
        id: formId,
        title: 'Reset',
        text: `Current code: ${codeId}`,
        actions: {
          confirm: {
            text: 'Reset',
            action: () => resetEmployeeCode(employeeId, codeId).then(() => onClose(formId)),
          },
          cancel: {
            text: 'Cancel',
            action: () => onClose(formId),
          },
        },
      })
    );

  const showGroupDeleteDialog = (group: any) =>
    onShow((formId: any) =>
      generateDialog({
        id: formId,
        title: 'Delete',
        text: 'Do you want to delete the group?',
        actions: {
          confirm: {
            text: 'Delete',
            action: () => onRemoveGroup(group).then(() => onClose(formId)),
          },
          cancel: {
            text: 'Cancel',
            action: () => onClose(formId),
          },
        },
      })
    );

  const showGroupCodeDialog = (groupId: number, codeId: number) =>
    onShow((formId: number) =>
      generateDialog({
        id: formId,
        title: 'Reset',
        text: `Current code: ${codeId}`,
        actions: {
          confirm: {
            text: 'Reset',
            action: () => resetGroupCode(groupId, codeId).then(() => onClose(formId)),
          },
          cancel: {
            text: 'Cancel',
            action: () => onClose(formId),
          },
        },
      })
    );

  showLoadForm = (formValues: { data: string; initialTabs: any } | undefined = undefined) =>
    onShow((formId: number) =>
      generateForm({
        dialog: {
          id: formId,
          submit: formValues ? onEditLoad : onAddLoad,
        },
        setup: loadFormSetup(formId, {
          dispatchers: 'dispatchers',
          brokers: 'brokers',
          units: 'units',
        }),
        formValues: formValues || formatLoadValues({}),
        isAdding: !formValues,
      })
    );

  const showLoadDeleteDialog = (load: any) =>
    onShow((formId: any) =>
      generateDialog({
        id: formId,
        title: 'Delete',
        text: 'Do you want to delete the load?',
        actions: {
          confirm: {
            text: 'Delete',
            action: () => onRemoveLoad(load).then(() => onClose(formId)),
          },
          cancel: {
            text: 'Cancel',
            action: () => onClose(formId),
          },
        },
      })
    );

  showUnitForm = (formValues: { data: any; initialTabs: any } | undefined = undefined) =>
    onShow((formId: any) =>
      generateForm({
        dialog: {
          id: formId,
          submit: formValues ? onEditUnit : onAddUnit,
        },
        setup: unitFormSetup(formId, {
          loads: 'loads',
          drivers: 'drivers',
          trucks: 'trucks',
          trailers: 'trailers',
        }),
        formValues: formValues || formatUnitValues({}),
        isAdding: !formValues,
      })
    );

  const showUnitDeleteDialog = (unit: any) =>
    onShow((formId: any) =>
      generateDialog({
        id: formId,
        title: 'Delete',
        text: 'Do you want to delete the unit?',
        actions: {
          confirm: {
            text: 'Delete',
            action: () => onRemoveUnit(unit).then(() => onClose(formId)),
          },
          cancel: {
            text: 'Cancel',
            action: () => onClose(formId),
          },
        },
      })
    );

  showConnectionForm = (formValues: { data: string; initialTabs: any } | undefined = undefined) =>
    onShow((formId: number) =>
      generateForm({
        dialog: {
          id: formId,
          submit: formValues ? onEditConnection : onAddConnection,
        },
        setup: connectionFormSetup(formId, {
          units: 'units',
        }),
        formValues: formValues || formatConnectionValues({}),
        isAdding: !formValues,
      })
    );

  showAssetForm = (formValues: { data: string; initialTabs: any } | undefined = undefined) =>
    onShow((formId: number) =>
      generateForm({
        dialog: {
          id: formId,
          submit: formValues ? onEditAsset : onAddAsset,
        },
        setup: assetFormSetup(formId, {}),
        formValues: formValues || formatAssetValues({}),
        isAdding: !formValues,
      })
    );

  const showAssetDeleteDialog = (unit: any) =>
    onShow((formId: number) =>
      generateDialog({
        id: formId,
        title: 'Delete',
        text: 'Do you want to delete the asset?',
        actions: {
          confirm: {
            text: 'Delete',
            action: () => onRemoveAsset(unit).then(() => onClose(formId)),
          },
          cancel: {
            text: 'Cancel',
            action: () => onClose(formId),
          },
        },
      })
    );

  return {
    showEmployeeForm,
    showEmployeeDeleteDialog,
    showEmployeeCodeDialog,
    showGroupForm,
    showGroupDeleteDialog,
    showGroupCodeDialog,
    showLoadForm,
    showLoadDeleteDialog,
    showUnitForm,
    showUnitDeleteDialog,
    showConnectionForm,
    showAssetForm,
    showAssetDeleteDialog,
  };
};

export default useBaseForm;

import withAuthorization from 'session/withAuthorization';

import Card from '@mui/material/Card';

import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import { Icon, IconButton } from '@mui/material';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import DataTableInfoCell from 'components/DataTable/DataTableInfoCell';
import DataTableRolesCell from 'components/DataTable/DataTableRolesCell';
import useFirebaseEmpoyeeAPI from 'services/data/Firebase/firebaseEmpoyeeAPI';
import { useDeepCompareMemo } from 'use-deep-compare';
import useBaseForm from 'hooks/useBaseForm';

const employeeTable = {
  columns: [
    { Header: 'name', accessor: (d: any) => `${d.firstName} ${d.lastName}`, width: '20%' },
    { Header: 'status', accessor: 'status', width: '7%' },
    {
      Header: 'roles',
      accessor: 'roles',
      Cell: ({ row }: any) => (
        <DataTableRolesCell row={row.original} actions={row.values.actions} />
      ),
      width: '7%',
      alignCell: 'center',
    },
    { Header: 'email', accessor: 'contact.email' },
    { Header: 'phone', accessor: 'contact.phone' },
    {
      Header: 'info',
      accessor: 'info',
      Cell: ({ row }: any) => <DataTableInfoCell row={row.original} actions={row.values.actions} />,
    },
    {
      Header: 'actions',
      accessor: 'actions',
      Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
      alignCell: 'right',
      isSortable: false,
    },
  ],
};

function Employees() {
  const employeeAPI = useFirebaseEmpoyeeAPI();
  const { employees } = employeeAPI;

  const { showEmployeeForm, showEmployeeDeleteDialog, showEmployeeCodeDialog } = useBaseForm();

  const onHistory = (events: any) => console.log('handleHistory for', events);

  const mapEmployees =
    (employees &&
      employees?.map((employee: any) => {
        if (!employee) {
          return null;
        }

        return {
          ...employee,
          actions: {
            code: employee.code
              ? () => showEmployeeCodeDialog(employee.id, employee.code)
              : undefined,
            history: () => onHistory(employee.events),
            edit: (values: any = employee, tabs: any = {}) =>
              showEmployeeForm({ ...values, initialTabs: tabs }),
            delete: () => showEmployeeDeleteDialog(employee),
          },
        };
      })) ||
    [];

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={employeeTable.columns}
        data={mapEmployees}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled={process.env.FEATURE_EMPLOYEE_EVENTS !== 'true'}>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <IconButton onClick={() => showEmployeeForm()}>
              <Icon fontSize="small">person_add_alt1</Icon>
            </IconButton>
          </>
        }
      />
    ),
    [employees]
  );

  return (
    <DashboardLayout>
      <Card>{generateTable}</Card>
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(Employees);

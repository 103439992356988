// TODO  REMOVE FOR PRODUCTION

import { JSXElementConstructor, Key, ReactElement, useEffect, useState } from 'react';

// react-router components
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ROUTES from 'navigation/const';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/common/MDBox';

// Material Dashboard 2 PRO React TS exampless
import Sidenav from 'navigation/Sidenav';
import Configurator from 'components/Configurator';

import Authentication from 'screens/authentication';

// Material Dashboard 2 PRO React TS contexts
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from 'context';

// Material Dashboard 2 PRO React TS themes
import theme from 'assets/theme';

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from 'assets/theme-dark';

// Material Dashboard 2 PRO React TS routes
import routes from 'navigation/routes';

// Images
import brandWhite from 'assets/images/logo-ct.png';
import brandDark from 'assets/images/logo-ct-dark.png';

import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { setAuth } from 'redux/slices/userSlice';
import { useDeepCompareMemo } from 'use-deep-compare';

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const dispatchUser = useDispatch();

  const auth = useSelector((state: any) => state.firebase.auth);
  // eslint-disable-next-line arrow-body-style
  const profile = useSelector((state: any) => state.firebase.profile);
  const isAuthorized = () => isLoaded(auth) && !isEmpty(auth);

  useFirestoreConnect(() => [
    {
      collection: `company/${profile?.company?.companyId}/groups`,
      storeAs: 'groups',
    },
    {
      collection: `company/${profile?.company?.companyId}/loads`,
      storeAs: 'loads',
    },
    {
      collection: `company/${profile?.company?.companyId}/units`,
      storeAs: 'units',
    },
    {
      collection: `company/${profile?.company?.companyId}/employees`,
      storeAs: 'employees',
    },
    {
      collection: `company/${profile?.company?.companyId}/connections`,
      storeAs: 'connections',
    },
    {
      collection: `company/${profile?.company?.companyId}/assets`,
      storeAs: 'assets',
    },
  ]);

  const formsStack = useSelector((state: any) => state?.forms?.formsStack || {});

  useEffect(() => {
    if (isAuthorized()) {
      dispatchUser(setAuth(auth));
      localStorage.setItem('authUser', JSON.stringify(auth));
      localStorage.setItem('profile', JSON.stringify(profile));
    }
  }, [auth, profile]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;

    if (document.scrollingElement) {
document.scrollingElement.scrollTop = 0;
}
  }, [pathname]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      }
    );

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const generateFormStack = useDeepCompareMemo(
    () => Object.values(formsStack).map((form) => form),
    [Object.values(formsStack).length]
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <>
        <CssBaseline />
        {generateFormStack}
        {layout === 'dashboard' && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="AIDispatcher"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === 'vr' && <Configurator />}
        <Routes>
          <Route path="/authentication" element={<Authentication />} key={ROUTES.AUTHENTICATION} />
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to={ROUTES.DISPATCH + ROUTES.BOARD} />} />
        </Routes>
      </>
    </ThemeProvider>
  );
}

export default App;
